import React, { Component } from "react";
import Lockr from "lockr";
import HeartGray from "../../../../../assets/images/HeartGray.svg";
import HeartRed from "../../../../../assets/images/HeartRed.svg";

class Hearts extends Component {
  constructor() {
    super();

    this.state = {
      currentUser: Lockr.get("managerReadme:currentUser")
    };

    this.loveOrHate = this.loveOrHate.bind(this);
  }

  render() {
    const hearts = this.props.hearts;

    return (
      <div onClick={this.loveOrHate} className="hearts">
        <img src={this.heartIcon()} className="heart-image" />
        {hearts.length}
      </div>
    )
  }

  loveOrHate() {
    if (this.state.currentUser) {
      fetch(`/comments/${this.props.commentId}/love_or_hate`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Token token=${Lockr.get('managerReadme:jwt')}`
        })
      })
        .then(response => response.json())
        .then(data => {
          this.props.setHearts(data)
        })
    } else {
      this.props.toggleSignInModal();
    }
  }

  heartIcon() {
    const hearts = this.props.hearts;
    const currentUser = Lockr.get("managerReadme:currentUser");

    if (currentUser) {
      const liked = hearts.find(heart => heart.user_id === currentUser.data.id)

      if (liked)
        return HeartRed;
      else
        return HeartGray;
    } else {
      return HeartGray;
    }
  }
}

export default Hearts;
