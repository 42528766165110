import React, { Component } from "react";
import LinkableName from "./Company/LinkableName";

import profileImage from '../../../../assets/images/default_profile_Image.png';

class AuthorSection extends Component {
  render() {
    const { user, readme } = this.props;

    let userImage = user.profile_image ? user.profile_image : profileImage;
    const userImageStyle = { backgroundImage: "url(" + userImage + ")" };

    return (
      <div className="author-section">
        <div className="author-image-container">
          <div className="author-image" style={userImageStyle}/>
          <span className="author-info">
            <b>
              {user.display_name || user.name}
              {readme.position ? `, ${readme.position} ` : ""}
              <LinkableName company={readme.company} />
            </b>
          </span>
        </div>
      </div>
    )
  }
}

export default AuthorSection;
