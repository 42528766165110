import React, { Component } from 'react';
import Readmes from "./Company/Readmes";
import Lockr from "lockr";

class ReviewRequest extends Component {
  fetchReadmes = () => {
    const url = `/admin/readmes?rr=1`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token token=${Lockr.get('managerReadme:jwt')}`
    };
    return fetch(url, { headers: headers });
  };
  render() {
    return (
      <div className="light-grey-section">
        <Readmes fetchReadmes={this.fetchReadmes} title="Readmes waiting for a review:"/>
      </div>
    )
  }
}

export default ReviewRequest;
