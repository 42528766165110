import React from "react";
import Lockr from "lockr";
import _ from "lodash";
import moment from "moment";
import { Api } from "../../middleware/api";
import R from "../../lib/redactor.js";
import alignment from "../../lib/alignment";
import Select from "react-select";

export default class AdminReadme extends React.Component {
  constructor(props) {
    super(props);

    let currentUser = Lockr.get("managerReadme:currentUser");

    this.state = {
      readme: {},
      readmeText: "<h1>Motivation for this document</h1>\n<h1>Disclaimer</h1>\n<h1>My role</h1>\n<h1>What do I value most?</h1>\n<h1>What will disappoint me?</h1>\n<h1>Personal quirks</h1>\n<h1>Expectations 101</h1>",
      saving: false,
    };
  }

  componentDidMount() {
      let content = document.getElementById("content");

      $R(content, {
        buttons: ["html", "format", "bold", "italic", "ul", "link"],
        formatting: ["p", "blockquote", "h1", "h2", "pre"],
        plugins: ["alignment"],
        toolbarFixedTopOffset: 80,
        callbacks: {
          keydown: e => {
            let newContent = $R("#content", "source.getCode");
            this.updateReadme(newContent);
          },
          blur: e => {
            let newContent = $R("#content", "source.getCode");
            this.updateReadme(newContent);
          }
        }
      });

      $R("#content", "source.setCode", this.state.readmeText);
  }

  updateReadme = text => {
    this.setState({
      readmeText: text
    });
  };

  saveReadme = autoSaving => {
    this.setState({
      saving: true
    });

    let userPayload = {
      name: this.state.readme.twitterName,
      profile_image: this.state.readme.twitterImage
    };

    Api.saveUser(userPayload, Lockr.get("managerReadme:jwt")).then(u => {
      let payload = {
        readme: {
          content: this.state.readmeText.text,
          company: this.state.readme.company,
          experience: this.state.readme.experience,
          manager_type: this.state.readme.manager_type,
          position: this.state.readme.position,
          private: false,
          user_id: u.id,
          updated_at: moment()
        }
      };

      Api.createReadme(payload, Lockr.get("managerReadme:jwt")).then(res => {
        this.setState({
          saving: false
        });

        window.location.href = `/readme/${u.name}`;
      });
    });
  };

  updatePosition = event => {
    this.setState({
      readme: Object.assign({}, this.state.readme, {
        position: event.target.value
      })
    });
  };

  updateExperience = response => {
    this.setState({
      readme: Object.assign({}, this.state.readme, {
        experience: response.value
      })
    });
  };

  updateManagerType = response => {
    this.setState({
      readme: Object.assign({}, this.state.readme, {
        manager_type: response.value
      })
    });
  };

  updateTwitterName = event => {
    this.setState({
      readme: Object.assign({}, this.state.readme, {
        twitterName: event.target.value
      })
    });
  };

  updateTwitterImage = event => {
    this.setState({
      readme: Object.assign({}, this.state.readme, {
        twitterImage: event.target.value
      })
    });
  };

  updateCompany = event => {
    this.setState({
      readme: Object.assign({}, this.state.readme, {
        company: event.target.value
      })
    });
  };

  render() {
    return (
      <div className="container">
        <div className="edit-readme admin">
          <div className="row">
            <div className="col-11 col-sm-10 col-md-10 col-lg-8 ml-auto mr-auto">
              <div className="user-info">
                <div className="row">
                  <div className="col-12">
                    <div>
                      <input
                        type="text"
                        placeholder="Twitter Name"
                        onChange={this.updateTwitterName}
                      />
                      <br />
                      <input
                        type="text"
                        className="full-width"
                        placeholder="Twitter Profile Image"
                        onChange={this.updateTwitterImage}
                      />
                      <br />
                      <input
                        type="text"
                        placeholder="Postion"
                        onChange={this.updatePosition}
                      />
                      <br />
                      <input
                        type="text"
                        placeholder="Company"
                        onChange={this.updateCompany}
                      />
                      <br />
                      <label className="mr-3">Years of experience</label>
                      <br />
                      <Select
                        name="experience"
                        placeholder="Please select...."
                        clearable={false}
                        onChange={this.updateExperience}
                        value={this.state.readme.experience}
                        options={[
                          { value: "0-2", label: "0-2 Years" },
                          { value: "3-8", label: "3-8 Years" },
                          { value: "8+", label: "8+ Years" }
                        ]}
                      />

                      <span className="manager-type">
                        <label className="mr-3">Manager Level</label>
                        <br />
                        <Select
                          name="manager-type"
                          clearable={false}
                          placeholder="Please select...."
                          value={this.state.readme.manager_type}
                          onChange={this.updateManagerType}
                          options={[
                            { value: "M", label: "Manager" },
                            { value: "M.O.M.", label: "Manager of Managers" }
                          ]}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="light-grey-section readme-editor">
                <textarea id="content" defaultValue={this.state.readmeText}></textarea>
              </div>

              <div className="row edit-readme-controls">
                <div className="col-12">
                  <button
                    type="submit"
                    value="Save"
                    className="ghost-button ghost-button-small"
                    onClick={() => this.saveReadme(false)}
                  >
                    {this.state.saving ? (
                      <span>
                        <i className="fa fa-spinner fa-spin mr-2" />
                        <span> Saving</span>
                      </span>
                    ) : (
                      <span>Save</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
