import React from 'react';
import Lockr from 'lockr';
import { Link, withRouter } from 'react-router-dom'
import DropdownMenu from 'react-dd-menu';
import { Api } from '../middleware/api';

// Images
import profileImage from '../../../../assets/images/default_profile_Image.png';
import logo from "../../../../assets/images/logo.svg";
import logoWhite from "../../../../assets/images/logo_white.svg";
import menu from "../../../../assets/images/menu.svg";

// Components
import SignInModal from "./SignInModal";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: Lockr.get('managerReadme:currentUser'),
      isMenuVisible: false,
      showMenuLinks: (this.props.location.pathname !== "/edit-readme"),
      showSignInModal: false
    }
  }

  componentWillReceiveProps({ location }) {
    this.setState({
      showMenuLinks: (location.pathname !== "/edit-readme"),
      isMenuVisible: false,
      currentUser: Lockr.get('managerReadme:currentUser'),
      navMenu: false
    });
  }

  logOut = () => {
    Api.logOut();
    this.props.history.push('/');
  };

  toggleMenu = () => {
    this.setState(prevState => {
      return { isMenuVisible: !prevState.isMenuVisible }
    });
  };

  closeMenu = () => {
    this.setState({ isMenuVisible: false })
  };

  openNavMenu = () => {
    this.setState({ navMenu: true });
  };

  closeNavMenu = () => {
    this.setState({ navMenu: false });
  };

  openSignInModal = (e) => {
    e.preventDefault();
    this.setState({ showSignInModal: true });
  };

  toggleSignInModal = () => {
    this.setState({ showSignInModal: !this.state.showSignInModal });
  };

  render() {
    const menuOptions = {
      isOpen: this.state.isMenuVisible,
      close: () => {},
      align: 'left',
    };

    let showMenuLink = this.state.showMenuLinks;
    let userData = this.state.currentUser;
    let user = userData ? userData.data : userData;
    let userButtonStyle = user ? { backgroundImage: `url(${user.profile_image || profileImage})` } : {};

    return(
      <div>
        <SignInModal
          toggleSignInModal={this.toggleSignInModal}
          show={this.state.showSignInModal}
          header={"Sign up to save your changes!"}
          signupMode={false}
        />
        <nav className="navbar navbar-nav">
          <div className="container">
            <div className="navbar-brand">
              <Link to="/">
                <img className="mr-1" src={logo} alt="manager readme logo" />
                <span className="logo-font">MANAGER README</span>
              </Link>
            </div>

            <div className="mobile-menu-wrapper">
              <ul className="nav list-inline d-flex d-lg-none align-items-center">
                {!userData && <li className="list-line-item ml-0">
                  <img className="menu-icon" src={menu} onClick={this.openNavMenu}/>
                </li>}

                {userData && <li className="ml-0 list-line-item">
                  <span
                    style={userButtonStyle}
                    className='user-button'
                    onClick={this.toggleMenu}>
                  </span>

                  <DropdownMenu {...menuOptions}>
                    {
                      user.is_admin &&
                      <Link to='/admin'>
                        <li> Admin </li>
                      </Link>
                    }
                    <Link to='/edit-readme'>
                      <li>Edit Readme</li>
                    </Link>
                    {userData.readme_private ?
                      <Link to={`/readme/${user.name}/${user.code}`}>
                        <li>View Readme</li>
                      </Link>
                    :
                      <Link to={`/readme/${user.name}`}>
                        <li>View Readme</li>
                      </Link>
                    }
                    <Link to='/settings'>
                      <li>Settings</li>
                    </Link>
                    <Link to='/' onClick={this.logOut}><li>Logout</li></Link>
                  </DropdownMenu>
                </li>}
              </ul>

              {this.state.navMenu && <div className="mobile-menu">
                <div className="mobile-menu-header">
                  <img src={logoWhite}/>
                  <i className="fa fa-close fa-2x font-white" onClick={this.closeNavMenu}/>
                </div>

                <div className="mobile-menu-body">
                  <ul>
                    <li>
                      <Link className="text-white" to="/features">
                        FEATURES
                      </Link>
                    </li>

                    <li>
                      <Link className="text-white" to="/use-cases">
                        USE CASES
                      </Link>
                    </li>

                    <li>
                      <Link className="text-white" to="/community">
                        COMMUNITY
                      </Link>
                    </li>

                    <li>
                      <Link className="text-white" to="javascript:void(0)" onClick={this.openSignInModal}>
                        LOGIN
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>}
            </div>

            <ul className="nav list-inline relative d-none d-lg-flex align-items-center">
              {showMenuLink &&
              <li className="list-line-item">
                <Link
                  to="/features"
                  className={
                    window.location.pathname === "/"
                      ? "mr-sm-0 header-link"
                      : "mr-sm-0"
                  }
                >
                  FEATURES
                </Link>
              </li>
              }
              {showMenuLink &&
              <li className="list-line-item">
                <Link
                  to="/use-cases"
                  className={
                    window.location.pathname === "/"
                      ? "mr-sm-0 header-link"
                      : "mr-sm-0"
                  }
                >
                  USE CASES
                </Link>
              </li>
              }
              {showMenuLink &&
              <li className="list-line-item">
                <Link
                  to="/community"
                  className={
                    window.location.pathname === "/"
                      ? "mr-sm-0 header-link"
                      : "mr-sm-0"
                  }
                >
                  COMMUNITY
                </Link>
              </li>
              }

              {!userData && showMenuLink && <li className="list-line-item">
                <Link
                  to="javascript:void(0)"
                  onClick={this.openSignInModal}
                  className={
                    window.location.pathname === "/"
                      ? "mr-sm-0 header-link"
                      : "mr-sm-0"
                  }
                >
                  LOGIN
                </Link>
              </li>}

              {userData && <li>
                <span
                  style={userButtonStyle}
                  className='user-button'
                  onClick={this.toggleMenu}>
                </span>

                <DropdownMenu {...menuOptions} >
                  {
                    user.is_admin &&
                    <Link to='/admin'>
                      <li> Admin </li>
                    </Link>
                  }
                  {
                    user.is_admin &&
                    <Link to='/review-requests'>
                      <li> Review Requests </li>
                    </Link>
                  }
                  <Link to='/edit-readme'>
                    <li>Edit Readme</li>
                  </Link>
                  {userData.readme_private ?
                    <Link to={`/readme/${user.name}/${user.code}`}>
                      <li>View Readme</li>
                    </Link>
                  :
                    <Link to={`/readme/${user.name}`}>
                      <li>View Readme</li>
                    </Link>
                  }
                  <Link to='/settings'>
                    <li>Settings</li>
                  </Link>
                  <Link to='/' onClick={this.logOut}><li>Logout</li></Link>
                </DropdownMenu>
              </li>}
            </ul>
          </div>
        </nav>
      </div>
    )
  }
}

export default withRouter(Header);
