import React, { Component } from "react";
import NewComment from "./NewComment";
import Hearts from "./Hearts";
import Lockr from "lockr";
import timeago from "timeago.js";
import Fingers from "../../../../../assets/images/MR_fingers.svg";
import profileImage from '../../../../../assets/images/default_profile_Image.png';
import CommentApi from "../../middleware/commentApi";

class Comment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reply: false,
      hearts: props.hearts,
      currentUser: Lockr.get("managerReadme:currentUser")
    };

    this.setReply = this.setReply.bind(this);
    this.setHearts = this.setHearts.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
  }

  render() {
    const { commentId, body, replies, isPrivate, updatedAt, user } = this.props;
    const { hearts, reply, currentUser } = this.state;
    const isAdmin = currentUser ? currentUser.data.is_admin : false;
    const isOwner = currentUser ? currentUser.data.id === user.id : false;

    return (
      <div>
        <div className="comment" id={`comment-${commentId}`}>
          <div className="comment-heading flex-box justify-content-between">
            <div className="comment-user">
              <img
                src={user.profile_image || profileImage}
                alt={`${user.display_name || user.name}'s profile image`}
                className="profile-image"
              />
              <b>{user.display_name || user.name}</b>
            </div>

            {user.reviewer &&
              <div className="reviewer-badge reviewer-badge-comments">
                REVIEWER
                <img className="fingers" src={Fingers} />
              </div>
            }

            {isPrivate &&
              <div className="private-info pull-right">
                This comment is private
              </div>
            }
          </div>

          <div className="comment-body" dangerouslySetInnerHTML={{ __html: body }} />

          <div className="flexbox">

            <Hearts
              hearts={hearts}
              setHearts={this.setHearts}
              commentId={commentId}
              toggleSignInModal={this.props.toggleSignInModal}
            />
            <div className="comment-updated-at">
              {timeago().format(updatedAt)}
            </div>

          {(isAdmin || isOwner) &&
            <a href="#" className="pull-right" onClick={this.deleteComment}>
              Delete
            </a>
          }
            <a href="#" className="pull-right mr-2" onClick={this.setReply}>
              Reply
            </a>
          </div>
        </div>

        <div className="comment-replies">
          {reply &&
            <NewComment
              ownerName={user.display_name || user.name}
              ownerId={user.id}
              commentId={this.props.commentId}
              readmeId={this.props.readmeId}
              commentsUrl={this.props.commentsUrl}
              fetchComments={this.props.fetchComments}
              foldNewComment={this.setReply}
              toggleSignInModal={this.props.toggleSignInModal}
              isPrivate={isPrivate}
            />
          }

          {replies && replies.length > 0 && replies.map(reply =>
            <Comment
              key={reply.id}
              commentId={commentId}
              body={reply.body}
              replies={reply.replies}
              hearts={reply.hearts}
              isPrivate={reply.private}
              updatedAt={reply.updated_at}
              user={reply.user}
              commentsUrl={`/comments/${reply.id}/comments`}
              fetchComments={this.props.fetchComments}
              commentId={reply.id}
              readmeId={this.props.readmeId}
              toggleSignInModal={this.props.toggleSignInModal}
            />
          )}
        </div>
      </div>
    )
  }

  setReply(event) {
    event && event.preventDefault()

    if (this.state.currentUser) {
      this.setState((prevState) => {
        return { reply: !prevState.reply }
      })
    } else {
      this.props.toggleSignInModal()
    }
  }

  setHearts(hearts) {
    this.setState({ hearts })
  }

  deleteComment(event) {
    event.preventDefault()

    const result = confirm("Are you sure you want to delete the comment?")

    if (result) {
      const token = Lockr.get('managerReadme:jwt');
      CommentApi
        .deleteComment(this.props.commentId, token)
        .then(this.props.fetchComments)
    }
  }
}

export default Comment;
