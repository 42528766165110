import React from "react";

const LinkableName = ({company}) => {
  if (company) {
    return (
        <a href={`/company/${company.slug}`} className="font-pink">
          <span className="font-pink">
            @{company.name}
          </span>
        </a>
      )
  } else {
    return null;
  }
}

export default LinkableName;
