import React, { Component } from "react";
import Carousel from 'nuka-carousel';
import isMobile from "ismobilejs";

class Jobs extends Component {
  state = {}

  componentDidMount() {
    this.fetchJobs()
  }

  render() {
    const jobs = this.state.jobs;
    const slides = isMobile.any ? 1 : 3;

    if (!jobs) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <i className="fa fa-3x fa-spinner fa-spin" />
            </div>
          </div>
        </div>
      );
    }

    if (jobs.length !== 0) {
      return (
        <div className="container jobs mt-3">
          <div className="title">
            <b>Want to work with us?</b>
          </div>

          <Carousel
            slidesToShow={slides}
            slidesToScroll={slides}
            renderCenterLeftControls={({ previousSlide }) => (
              <div onClick={previousSlide} className="previous-slide">
                <i className="fa fa-arrow-left" aria-hidden="true"></i>
              </div>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <div onClick={nextSlide} className="next-slide">
                <i className="fa fa-arrow-right" aria-hidden="true"></i>
              </div>
            )}
            renderBottomCenterControls={() => null}
          >
            {jobs.map(job =>
              <div
                className="job"
                key={job.id}
              >
                <span className="job-title">
                  <b>{job.title}</b>
                </span>
                <br />
                <span className="job-location">
                  {job.location}
                </span>
                <br />
                <a
                  className="job-apply"
                  href={job.url}
                  target="_blank"
                >
                  <b>APPLY</b>
                </a>
              </div>
            )}
          </Carousel>
        </div>
      )
    }

    return null;
  }

  fetchJobs = () => {
    const url = `/companies/${this.props.slug}/jobs`;

    fetch(url)
      .then(res => {
        if (res.ok) {
          return res.json();
        } else {
          window.location.href = "/not_found"
        }
      })
      .then(data => {
        this.setState({
          jobs: data
        });
      });
  }
}

export default Jobs;
