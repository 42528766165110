import React from 'react'
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom'

// Top Level Components
import Snapshot from './components/Snapshot';

const App = (props) => (
  <Router>
    <div>
      <Route exact path='/snap/:username' component={Snapshot} />
    </div>
  </Router>
)

export default App;
