import React from "react";
import Lockr from "lockr";
import { Redirect } from 'react-router';

class MyReadme extends React.Component {
  constructor(props) {
    super(props);
    let userData = Lockr.get("managerReadme:currentUser");

    let url = '/';
    if (userData) {
      url = `/readme/${userData.data.name}`;
      if (userData.readme_private) {
        url = `/readme/${userData.data.name}/${userData.data.code}`;
      }
    }
    this.state = { url: url };
  }

  render() {
      return (<Redirect to={{ pathname: this.state.url }}/>);
  }
}

export default MyReadme;
