const getComments = (url, token, showAllComments) =>
  fetch(showAllComments ? url + '/private' : url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token token=${token}`
    }
  }).then(response => response.json())
    .then(comments => comments);

const getNumOfComments = (url, token) =>
  fetch(url + '/count', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token token=${token}`
    }
  }).then(response => response.json())
    .then(response => { return { numOfComments: response.count } });

const deleteComment = (commentId, token) =>
  fetch(`/comments/${commentId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token token=${token}`
    }
  }).then(response => response.json())
    .then(data => data);

const CommentsApi = {
  getComments,
  deleteComment,
  getNumOfComments
};

export default CommentsApi;
