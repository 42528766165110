import React, { Component } from "react";

class Card extends Component {
  render() {
    const company = this.props.company;

    return (
      <div className="company-card d-flex align-items-center flex-column">
        <div className="logo-container">
          <img className="logo" src={company.logo} />
        </div>

        <div className="divider" />

        <div className="description">
          {company.short_description}
        </div>

        <span className="mt-auto company-link">
          <a href={`/company/${company.slug}`}>
            <b>
              {this.companyLinkText(company.readmes_count)}
            </b>
          </a>
        </span>
      </div>
    )
  }

  companyLinkText = (readmesCount) => {
    return readmesCount === 1 ?
      `${readmesCount} Manager Readme` :
      `${readmesCount} Manager Readmes`;
  }
}

export default Card;
