import React from "react";

// Images
import icon1 from "../../../../../assets/images/MRM-icon1.png";
import icon2 from "../../../../../assets/images/MRM-icon2.png";
import icon3 from "../../../../../assets/images/MRM-icon3.png";
import quotes from "../../../../../assets/images/MRM-qicon.png";
import bgq from "../../../../../assets/images/MRM_bgq.png";
import jay from "../../../../../assets/images/MRM-jay.png";
import person from "../../../../../assets/images/MR_person_illustration.png";

export default class WhyReadme extends React.Component {
  render() {
    const relative = {
      position: "relative",
      zIndex: "2"
    };

    return (
      <div className="white-section why-readme text-center text-lg-left">
        <div className="container">
          <div className="row mobile-cta">
            <div className="d-md-none d-inline-block  col-lg-6">
              <a href="/edit-readme" className="ghost-button">
                Write your Readme
              </a>

              <p className="mt-2">
                <small className="font-pink">
                  Give it a try, no signup is needed!
                </small>
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-8 d-flex align-items-center">
              <h3 className="pitch-text mt-3 mb-3">
              One of the hardest parts of becoming a great leader is developing self-awareness. 
              Writing your Readme can be your outlet, then, share it with your team.
              </h3>
            </div>

            <div className="col-12 col-lg-4 person">
              <img src={person} className="img-fluid d-none d-lg-block" />
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-4 mb-5">
              <div className="text-lg-center">
                <img src={icon1} className="icon-1 text-center mb-3" />
              </div>

              <h5 className="mb-3">Build stronger relationships</h5>

              <p className="text-lg-left">
                When your teammates know your preferences and expectations, you can 
                spend more time on meaningful conversations, figuring out ways to utilize each other's strengths.
                <span className="font-pink">
                  {" "}
                  <a href="/use-cases">Use your Readme in your 1:1s.</a>
                </span>
              </p>
            </div>

            <div className="col-md-4  mb-5">
              <div className="text-lg-center">
                <img src={icon2} className="icon-2 mb-2" />
              </div>

              <h5 className="mb-3">Improve employees onboarding</h5>

              <p className="text-lg-left">
                Share your expectations and leadership style with new employees
                from Day 1 to make their onboarding smoother.
                <span className="font-pink">
                  {" "}
                  <a href="/use-cases">Use your Readme to guide them in their first 90 days&nbsp;</a>
                </span>
                on the job.
              </p>
            </div>

            <div className="col-md-4 mb-5">
              <div className="text-lg-center">
                <img src={icon3} className="text-center mb-3" />
              </div>

              <h5 className="mb-3">Attract talent that gets you</h5>

              <p className="text-lg-left">
                Find potential candidates by sharing your managerial style and
                values publicly. Read our tips on how to <span className="font-pink"><a href="/use-cases">use your Readme for hiring.</a></span>
              </p>
            </div>

            <div className="row get-started mt-5">
              <div
                style={relative}
                className="col-md-8 ml-auto mr-auto col-lg-5 text-lg-left"
              >
                <img style={relative} src={quotes} className="quotes-image" />
                <img src={bgq} className="quote-bg-image" />
                <p style={relative} className="quote">
                  People always harp on the importance <br />
                  of building trust and communicating - <br />
                  but they rarely say how. Write your user <br />
                  guide. Ask your team to reciprocate. <br />
                  That's how. <br /> <br />
                  <a href="http://firstround.com/review/the-indispensable-document-for-the-modern-manager/" target="_blank">The Indispensable Document <br/>for the Modern Manager</a>
                </p>
                <span style={relative}>
                  <img src={jay} className="mr-2 align-middle" />
                  <span>Jay Desai, CEO @ PatientPing</span>
                </span>
              </div>

              <div
                style={relative}
                className="get-started-section col-lg-7 text-lg-left"
              >
                <h3 className="mb-3">Ready to get started? We can help!</h3>
                <p className="text-lg-left mb-5">
                  Not sure how to begin writing your Readme?
                  <span className="font-pink">
                    {" "}
                    <a href="/features">From writing and template ideas, to FAQs and editing</a>{" "}
                  </span>
                  - we've got you covered! Once you've created your Readme, share it
                  with our reviewers and people you trust to get feedback.
                </p>

                <a href="/edit-readme" className="ghost-button mt-3">
                  Write your Readme
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
