import React from 'react';

class CustomInput extends React.Component {
  render() {
    return(
      <div className="custom-input">
        <input
          {...this.props.autocompleteProps}
          name={this.props.name}
          type={this.props.type || "text"}
          placeholder={this.props.placeholder}
          defaultValue={this.props.defaultValue}
          onChange={this.props.onChangeFunc}
        />
        {this.props.showError &&
        <span className="error-text">{this.props.errorText}</span>
        }
      </div>
    )
  }
}

export default CustomInput;
