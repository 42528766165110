import Lockr from 'lockr';
import 'isomorphic-fetch';
import _ from 'lodash';

const createReviewRequest = (token) => {
  let headers = {
    'Content-Type':'application/json',
    'Authorization':`Token token=${token}`
  };

  let reviewRequest = fetch(`/review_request/`, {
    method: 'post',
    headers: headers,
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return data;
    });

  return reviewRequest;
};

const getReadme = (readme, token) => {
  let headers = {
    'Content-Type': 'application/json',
    'Authorization': `Token token=${token}`
  };

  let url = `/readmes/${readme.user_id}`;

  let res = fetch(url, {
    headers: headers
  })
    .then((res) => {
      if(!res.ok) {
        // logOut();
      } else {
        return res.json();
      }
    })
    .then((data) => {
      return data
    });

  return res;
};

const getReviewRequest = (token) => {
  let headers = {
    'Content-Type': 'application/json',
    'Authorization': `Token token=${token}`
  };

  let url = `/review_request`;

  let res = fetch(url, {
    headers: headers
  })
    .then((res) => {
      if(!res.ok) {
        // logOut();
      } else {
        return res.json();
      }
    })
    .then((data) => {
      return data
    });

  return res;
};

const getTopics = (token) => {
  let headers = {
    'Content-Type': 'application/json',
    'Authorization': `Token token=${token}`
  };

  let url = `/topics`;

  let res = fetch(url, {
    headers: headers
  })
    .then((res) => {
      if(!res.ok) {
        // logOut();
      } else {
        return res.json();
      }
    })
    .then((data) => {
      return data
    });

  return res;
};

const getUser = (user_id, token) => {
  let headers = {
    'Content-Type': 'application/json',
    'Authorization': `Token token=${token}`
  };

  let user = fetch(`/users/${user_id}`, {
    headers: headers,
  })
    .then((res) => {
      return res.json()
    })
    .then((data) => {
      return data;
    });

  return user;
};

const isPraisedBy = (readme_user_id, praiser_id, token) => {
  let headers = {
    'Content-Type': 'application/json',
    'Authorization': `Token token=${token}`
  };

  let praised = fetch(`/is_praised/${readme_user_id}/${praiser_id}`, {
    headers: headers,
  })
    .then((res) => {
      if(res.ok) {
        return res.json()
      } else {
        return false;
      }
    })
    .then((data) => {
      if(data.errors) {
        return false;
      } else {
        return data;
      }
    });

  return praised;
};

const logOut = () => {
  Lockr.rm('managerReadme:jwt');
  Lockr.rm('managerReadme:currentUser');

  fetch('/logout', {
    method: 'delete'
  }).then(() => {
    return true;
  });
};

const praiseUser = (readme_user_id, praiser_id, token) => {
  let headers = {
    'Content-Type': 'application/json',
    'Authorization': `Token token=${token}`
  };

  let praise = fetch(`/praises/${readme_user_id}/${praiser_id}`, {
    method: 'post',
    headers: headers,
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return data;
    });

  return praise;
};

const saveUser = (user, token) => {
  let headers = {
    'Content-Type':'application/json',
    'Authorization':`Token token=${token}`
  };

  let u = fetch(`/admin/users/${user.name}`, {
    method: 'post',
    body: JSON.stringify(user),
    headers: headers,
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return data.user;
    });

  return u;
};

const updateUser = (user, token) => {
  let headers = {
    'Content-Type':'application/json',
    'Authorization':`Token token=${token}`
  };

  let u = fetch(`/users/${user.name}`, {
    method: 'put',
    body: JSON.stringify(user),
    headers: headers,
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return data;
    });

  return u;
};

const deleteTopic = (topic_id, token) => {
  let headers = {
    'Content-Type':'application/json',
    'Authorization':`Token token=${token}`
  };

  let t = fetch(`/topics/${topic_id}`, {
    method: 'delete',
    headers: headers,
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return data;
    });

  return t;
};

const deleteAnswer = (id, token) => {
  let headers = {
    'Content-Type':'application/json',
    'Authorization':`Token token=${token}`
  };

  let t = fetch(`/answers/${id}`, {
    method: 'delete',
    headers: headers,
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return data;
    });

  return t;
};

const saveTopic = (topic, token) => {
  let headers = {
    'Content-Type':'application/json',
    'Authorization':`Token token=${token}`
  };

  let payload = {
    title: topic.title,
  };

  let t = fetch(`/topics`, {
    method: 'post',
    body: JSON.stringify(payload),
    headers: headers,
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .then((t) => {
      _.each(topic.answers, (answer) => {
        answer.topic_id = t.id;
        saveAnswer(answer, token);
      })
    });

  return t;
};

const updateTopic = (payload, token) => {
  let headers = {
    'Content-Type':'application/json',
    'Authorization':`Token token=${token}`
  };

  let t = fetch(`/topics/${payload.topic.id}`, {
    method: 'put',
    body: JSON.stringify(payload),
    headers: headers,
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .then((t) => {
      _.each(payload.topic.answers, (answer) => {
        if(answer.id) {
          updateAnswer(answer, token);
        } else {
          answer.topic_id = t.topic.id;
          saveAnswer(answer, token);
        }
      })
    });

  return t;
};

const saveAnswer = (answer, token) => {
  let headers = {
    'Content-Type':'application/json',
    'Authorization':`Token token=${token}`
  };

  let payload = {
    answer: answer
  };

  let a = fetch(`/answers`, {
    method: 'post',
    body: JSON.stringify(payload),
    headers: headers,
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return data;
    });

  return a;
};

const updateAnswer = (answer, token) => {
  let headers = {
    'Content-Type':'application/json',
    'Authorization':`Token token=${token}`
  };

  let payload = {
    answer: answer
  };

  let a = fetch(`/answers/${answer.id}`, {
    method: 'put',
    body: JSON.stringify(payload),
    headers: headers,
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return data;
    });

  return a;
};

const saveReadme = (payload, token) => {
  let headers = {
    'Content-Type':'application/json',
    'Authorization':`Token token=${token}`
  };

  let readme = fetch(`/readmes/${payload.readme.id}`, {
    method: 'put',
    body: JSON.stringify(payload),
    headers: headers,
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        return { ok: false };
      }
    })
    .then((data) => {
      return data;
    });

  return readme;
};

const createReadme = (payload, token) => {
  let headers = {
    'Content-Type':'application/json',
    'Authorization':`Token token=${token}`
  };

  let readme = fetch(`/readmes/${payload.readme.user_id}`, {
    method: 'post',
    body: JSON.stringify(payload),
    headers: headers,
  })
    .then((res) => {
      if(res.ok) {
        return res.json();
      }
    })
    .then((data) => {
      return data;
    });

  return readme;
};

const unPraiseUser = (readme_user_id, praiser_id, token) => {
  let headers = {
    'Content-Type':'application/json',
    'Authorization':`Token token=${token}`
  };

  let praise = fetch(`/praises/${readme_user_id}/${praiser_id}`, {
    method: 'delete',
    headers: headers,
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return data;
    });

  return praise;
};

export const Api = {
  createReadme,
  createReviewRequest,
  deleteAnswer,
  deleteTopic,
  getReadme,
  getReviewRequest,
  getTopics,
  getUser,
  isPraisedBy,
  logOut,
  praiseUser,
  saveReadme,
  saveTopic,
  saveUser,
  unPraiseUser,
  updateAnswer,
  updateTopic,
  updateUser,
};
