import React from 'react';
import pencil from '../../../../assets/images/pencil.png';
import Lockr from "lockr";

export default class Welcome extends React.Component {

  constructor(props) {
    super(props);
    this.state = { showModal: true };
  }

  acknowledgedReadme = () => {
    Lockr.set("managerReadme:acknowledgedWelcomeModal", true);
    this.setState({ showModal: false });
  };

  render() {
    if (this.state.showModal) {
      return (
        <div className='welcome-popup'>
          {this.state.showModal &&
          <div className='container'>
            <div className='row'>
              <div className='col-md-9 ml-auto mr-auto text-center'>
                <h3>
                  Welcome to your Readme page, let's do some <img src={pencil} className='pencil'/>
                </h3>

                <p>
                  Don't worry, we all hate to start with a blank page. This is
                  why we took the liberty to set some topics and questions
                  you can answer to create your Readme in no time.
                </p>

                <p>
                  If you want more time to think about your Readme, that's okay.
                  As long as you keep the document private (it is private by default),
                  only people with a special link to it can see it.
                </p>
                <br/>
                <button className='ghost-button' onClick={this.acknowledgedReadme}>
                  Got it!
                </button>
              </div>
            </div>
          </div>}
        </div>
      )
    } else {
      return (
        <div />
      )
    }
  }
}
