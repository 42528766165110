import React, { Component } from "react";
import Autocomplete from "react-autocomplete";
import CustomInput from "../CustomInput";
import Lockr from "lockr";

class AutocompleteInput extends Component {
  state = { companies: [] }

  componentDidMount() {
    this.fetchCompanies()
  }

  render() {
    const menuStyle = {
      borderRadius: '3px',
      boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
      background: '#FFFFFF',
      padding: '0',
      fontSize: '90%',
      position: 'fixed',
      overflow: 'auto',
      maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
      zIndex: '999'
    }

    return (
      <Autocomplete
        getItemValue={(company) => company.name}
        items={this.state.companies}
        menuStyle={menuStyle}
        renderItem={(company, isHighlighted) =>
            <div
              key={company.id}
              style={{ background: isHighlighted ? 'lightgray' : 'white' }}
              className="autocomplete-suggestion"
            >
              {company.name}
            </div>
        }
        renderInput={props =>
            <CustomInput placeholder="Company"
              autocompleteProps={props}
              type="text"
              name="company"
              value={this.props.companyName}
              onChangeFunc={this.props.updateCompanyName}
              showError={this.props.showError}
              errorText="Company name can't be blank"
            />
        }
        value={this.props.companyName}
        onChange={this.props.updateCompanyName}
        onSelect={(value) => this.props.updateCompanyName(null, value)}
        shouldItemRender={this.shouldCompanyRender}
      />
    )
  }

  shouldCompanyRender = (company, value) =>
    company && value &&
      company.name.toLowerCase().includes(value.toLowerCase())


  fetchCompanies = () => {
    const url = `/companies_private`;

    fetch(url, {
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Token token=${Lockr.get('managerReadme:jwt')}`
      })
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        } else {
          window.location.href = "/not_found"
        }
      })
      .then(data => {
        if (data.error) {
          window.location.href = "/not_found";
        } else {
          this.setState({ companies: data })
        }
      })
  }
}

export default AutocompleteInput;
