import React, { Component } from "react";
import Lockr from 'lockr';
import "../../lib/redactor.js";
import { ToastContainer, toast } from 'react-toastify';

class NewComment extends Component {

  toastId = null;

  constructor(props) {
    super(props);

    this.state = {
      currentUser: Lockr.get("managerReadme:currentUser"),
      private: props.isPrivate,
      codeOfConductRead: false,
      error: false
    };

    this.createComment = this.createComment.bind(this);
    this.validateBody = this.validateBody.bind(this);
    this.validateCodeOfConduct = this.validateCodeOfConduct.bind(this);
    this.setBody = this.setBody.bind(this);
    this.setPrivate = this.setPrivate.bind(this);
    this.setCodeOfConductRead = this.setCodeOfConductRead.bind(this);
    this.handleDivClick = this.handleDivClick.bind(this);
  }

  componentDidMount() {
    this.setupRedactor();
  }

  setupRedactor = () => {
    const content = this.refs.newCommentTextbox;
    $R(content, { buttons: ["bold", "italic", "ul", "link", "redo", "undo"] });
  };

  render() {
    const commentPlaceholder = "Type here to share your feedback and add to the discussion";
    const currentUser = this.state.currentUser;
    const ownerIsCurrentUser = currentUser && currentUser.data ? currentUser.data.id === this.props.ownerId : currentUser;

    return (
      <div
        className='new-comment active'
        onClick={this.handleDivClick}>
        <ToastContainer autoClose={8000} style={{position: 'fixed', minWidth: '400px'}}/>
        <form>
          <textarea
            rows="3"
            className='new-comment-textbox'
            ref='newCommentTextbox'
            placeholder={commentPlaceholder}
          />

          {this.state.bodyError &&
            <div className="text-danger mt-2">{this.state.bodyError}</div>
          }
          {this.state.codeOfConductError &&
            <div className="text-danger">{this.state.codeOfConductError}</div>
          }

          <div className="submit-new-comment">
            <div>
              {!this.props.isPrivate && !ownerIsCurrentUser &&
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      className="form-check-input new-comment-checkbox"
                      checked={this.state.private}
                      onChange={this.setPrivate}
                    />
                    Share this feedback privately with {this.props.ownerName}
                  </label>
                </div>
              }

              <div className="form-check">
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    className="form-check-input new-comment-checkbox"
                    checked={this.state.codeOfConductRead}
                    onChange={this.setCodeOfConductRead}
                  />
                  I have read the <a href="/code-of-conduct" target="_blank">Code of Conduct</a>
                </label>
              </div>
            </div>

            <button
              className="ghost-button ghost-button-small pull-right"
              onClick={this.createComment}
              disabled={this.state.error}
            >
              Submit
            </button>
          </div>
        </form>

      </div>
    )
  }

  createComment(event) {
    event.preventDefault();

    if (!this.state.currentUser) {
      this.props.toggleSignInModal();
      return;
    }

    const content = this.refs.newCommentTextbox;

    let body = $R(content, 'source.getCode');
    const bodyIsValid = this.validateBody(body);
    const codeOfConductIsValid = this.validateCodeOfConduct(this.state.codeOfConductRead);

    if (
      this.state.error ||
      !bodyIsValid ||
      !codeOfConductIsValid
    ) return;

    const comment = {
      comment: {
        body: body,
        private: this.state.private,
        readme_id: this.props.readmeId
      }
    };

    fetch(this.props.commentsUrl, {
      body: JSON.stringify(comment),
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Token token=${Lockr.get('managerReadme:jwt')}`
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.ok) {
          this.props.fetchComments();
          this.props.foldNewComment && this.props.foldNewComment();
          this.setState({ private: false, codeOfConductRead: false });
          $R(content, 'source.setCode', '');
        } else {
          this.popupErrorToastr();
        }
      }).catch(() => {
        this.popupErrorToastr();
      }
    )
  }

  popupErrorToastr = () => {
    const ErrorToastElement = () => <div className='error-toastr'><b>Failed to save your comment :(</b><br/>Please copy the content of your comment to avoid potential data loss, and refresh the page</div>;
    if (!toast.isActive(this.toastId)) {
      this.toastId = toast.error(ErrorToastElement, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  setBody(event) {
    this.validateBody(event.target.value);
  }

  validateBody(body) {
    if (body.trim() === "") {
      this.setState({ bodyError: "Comment can't be empty." });
      return false;

    } else {
      this.setState({ bodyError: false });

      return true;
    }
  }

  setPrivate(event) {
    this.setState({ private: event.target.checked })
  }

  setCodeOfConductRead(event) {
    this.validateCodeOfConduct(event.target.checked)
  }

  validateCodeOfConduct(codeOfConductRead) {
    if (codeOfConductRead) {
      this.setState({
        codeOfConductRead,
        codeOfConductError: false
      });

      return true;
    } else {
      this.setState({
        codeOfConductRead,
        codeOfConductError: "You must read the Code of Conduct."
      });

      return false;
    }
  }

  handleDivClick(event) {
    if (!this.state.currentUser) {
      event.preventDefault();
      this.props.toggleSignInModal();
    }
  }
}

export default NewComment;
