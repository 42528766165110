import React, { Component } from "react";
import Lockr from 'lockr';

class Settings extends Component {
  state = { fetched: false }

  componentDidMount() {
    fetch("/notifications", {
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Token token=${Lockr.get('managerReadme:jwt')}`
      })
    })
      .then(response => response.json())
      .then(notification => {
        this.setState({
          commentOnReadme: notification.comment_on_readme,
          commentOnComment: notification.comment_on_comment,
          newsletter: notification.newsletter,
          fetched: true
        })
      })
  }

  render() {
    if (!this.state.fetched)
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <i className="fa fa-3x fa-spinner fa-spin" />
            </div>
          </div>
        </div>
      );

    return (
      <div className="settings container">
        <h1>Settings</h1>

        <p>Manage your email notifications:</p>
        <div className="notifications">
          <div className="form-check">
            <label className="form-check-label">
              <input
                type="checkbox"
                className="form-check-input"
                checked={this.state.commentOnReadme}
                onChange={this.updateNotificationSettings}
                name="commentOnReadme"
              />
              Notify me when someone adds a comment on my Readme.
            </label>
          </div>

          <div className="form-check">
            <label className="form-check-label">
              <input
                type="checkbox"
                className="form-check-input"
                checked={this.state.commentOnComment}
                onChange={this.updateNotificationSettings}
                name="commentOnComment"
              />
              Notify me when someone replies to my comment.
            </label>
          </div>

          <div className="form-check">
            <label className="form-check-label">
              <input
                type="checkbox"
                className="form-check-input"
                checked={this.state.newsletter}
                onChange={this.updateNotificationSettings}
                name="newsletter"
              />
              Notify me about new Interviews and Readmes shared in our
              community (no more than once a week).
            </label>
          </div>

          <p className="mt-2">
            {this.state.updating && "Updating notifications settings..."}
            {this.state.updatingFinished && "Successfully updated notifications settings."}
          </p>
        </div>
      </div>
    )
  }

  updateNotificationSettings = (event) => {
    const checkboxName = event.target.name;

    this.setState(prevState => {
      return { [checkboxName]: !prevState[checkboxName] }
    },
      this.updateRequest
    )
  }

  updateRequest = () => {
    let { commentOnComment, commentOnReadme, newsletter } = this.state

    const notification = {
      "notification": {
        comment_on_comment: commentOnComment,
        comment_on_readme: commentOnReadme,
        newsletter: newsletter
      }
    }

    this.setState({ updating: true })

    fetch("/notifications", {
      body: JSON.stringify(notification),
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Token token=${Lockr.get('managerReadme:jwt')}`
      })
    })
      .then(response => response.json())
      .then(notification => {
        this.setState({
          commentOnReadme: notification.comment_on_readme,
          commentOnComment: notification.comment_on_comment,
          newsletter: notification.newsletter,
          updating: false,
          updatingFinished: true
        })

        setTimeout(() => this.setState({ updatingFinished: false }), 3000)
      })
  }
}

export default Settings;
