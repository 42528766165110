import React from 'react';
import {Api} from '../middleware/api';
import Lockr from 'lockr';


export default class FirstView extends React.Component {
  acknowledgedReadme = () => {
    let user = this.props.currentUser;
    user.data.first_view = false;
    Api.updateUser(user.data, Lockr.get('managerReadme:jwt')).then((u) => {

      let currentUser = {
        data: u,
        exp: user.exp,
        readme: user.readme,
        readme_private: user.readme_private
      };

      Lockr.set('managerReadme:currentUser', currentUser);
      window.location.reload();
    })
  };

  render() {
    return(
      <div className='welcome-popup'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8 ml-auto mr-auto text-center'>
              <h3>
                Good, you're ready to iterate on your Readme
              </h3>

              <p>
                You can click on <strong>COLLECT FEEDBACK</strong> to get a quick
                note you can share with your peers, teammates, and people who know you really well.
              </p>

              <p>
                We generated a secret URL for you as long as your Readme is in private
                mode. Click on <strong>COPY LINK</strong> to share with people you want.
              </p>

              <p>
                Lastly, when you feel ready, change your Readme to <strong>PUBLIC MODE</strong> so others could discover it and learn from your writing.
              </p>

              <button className='ghost-button' onClick={this.acknowledgedReadme}>
                Got it!
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
