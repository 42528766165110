import React, { Component } from "react";
import { Link } from 'react-router-dom'

class LayoutFooter extends Component {
  render() {
    return (
      <footer className="white-section pt-3 pb-2 text-center">
        <h6>
          <strong>
            Made with <i className="fa fa-heart mr-1" /> by Oren Ellenbogen,
            maker of{" "}
            <a
              className="swlw-a-footer"
              target="_blank"
              href="https://softwareleadweekly.com"
            >
              SoftwareLeadWeekly
            </a>
          </strong>
          <div className="footer-links">
            <Link to="/code-of-conduct" className="pl-5 pr-2">
              <span>Code of Conduct</span>
            </Link>
            |
            <Link to="/terms" className="pl-2 pr-2">
              <span>Terms of Service</span>
            </Link>
            |
            <Link to="/privacy" className="pl-2">
              <span>Privacy</span>
            </Link>
          </div>
        </h6>
      </footer>
    )
  }
}

export default LayoutFooter;
