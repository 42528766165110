import React from 'react';

// Components
import bg2 from '../../../../assets/images/BG2_features.png';

class PageFooter extends React.Component {
  render() {
    const bg2f = {
      background: `url(${bg2}) bottom no-repeat`,
      backgroundSize: 'cover',
      width: '100%',
      height: '500px'
    }

    return(
      <div style={bg2f}>
        <div className='row section-5 text-center'>
          <div className='col-md-5 ml-auto mr-auto mt-5'>
            <h3 className='font-white mt-5'>
              Ready to give it a go?
            </h3>

            <p className='font-white mt-5 bg2-p'>
              Open a Manager Readme template now and experiment with it while in
              private mode. Use our questions and suggestions to guide you as you brainstorm ideas.
            </p>

            <p className='mt-5'>
              <a href='/edit-readme' className='ghost-button ghost-button-white'>
                Write your Readme
              </a>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default PageFooter
