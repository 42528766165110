import React, { Component } from "react";
import ReactModal from "react-modal";
import { Link } from 'react-router-dom'
import { validateEmptyField } from "../utils/input.js";
import _ from 'lodash';
import { Api } from "../middleware/api";
import * as EmailValidator from 'email-validator';
import ReactPasswordStrength from 'react-password-strength';

// Components
import CustomInput from "./CustomInput";

// Images
import LogoIcon from '../../../../assets/images/logo-icon.png';
import TwitterLogoWhiteOnBlue from '../../../../assets/images/TwitterLogoWhiteOnBlue.png';
import modalBgImage from '../../../../assets/images/signInModalBg.png';

const customStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: '40%',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19)',
    padding: '20px 0 0 0 !important'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: '200'
  }
};

class SignInModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signupMode: this.props.signupMode,
      saveDisabled: true,
      valids: {
        name: false,
        displayName: false,
        email: false,
        password: false
      },
      pristines: {
        name: true,
        displayName: true,
        email: true,
        password: true
      },
      agreementChecked: false,
      errorMessage: ""
    };
  }

  changeToLoginMode = () => {
    let valids = _.clone(this.state.valids);
    this.setState({ signupMode: false, saveDisabled: !this._shouldEnableSave(false, valids), errorMessage: "" });
  };

  changeToSignUpMode = () => {
    let valids = _.clone(this.state.valids);
    this.setState({ signupMode: true, saveDisabled: !this._shouldEnableSave(true, valids, this.state.agreementChecked), errorMessage: "" });
  };

  validateName = event => {
    this.setState({ pristines: Object.assign({}, this.state.pristines, { name: false })});
    this._validateInput('name', event.target.value);
  };

  validateDisplayName = event => {
    this.setState({ pristines: Object.assign({}, this.state.pristines, { displayName: false })});
    this._validateInput('displayName', event.target.value);
  };

  validateEmail = event => {
    let email = event.target.value;
    let valid = EmailValidator.validate(email);
    this.setState({ pristines: Object.assign({}, this.state.pristines, { email: false })});
    if (valid) {
      this._validateInput('email', email);
    } else {
      this.setState({ valids: Object.assign({}, this.state.valids, { email: false }), saveDisabled: true });
    }
  };

  validatePassword = event => {
    this.setState({ pristines: Object.assign({}, this.state.pristines, { password: false })});
    this._validateInput('password', event.target.value);
  };

  setValidateNewPassword = event => {
    if (event.isValid) {
      this._validateInput('password', event.password);
    } else {
      this._validateInput('password', '');
    }
  };

  checkboxClicked  = () => {
    let checked = !this.state.agreementChecked;
    let saveDisabled;
    if (checked) {
      let valids = _.clone(this.state.valids);
      saveDisabled = !this._shouldEnableSave(true, valids, true);
    } else {
      saveDisabled = true;
    }
    this.setState({ agreementChecked: checked, saveDisabled: saveDisabled });
  };

  _validateInput = (name, field) => {
    let validField = validateEmptyField(field);
    let object = {};
    object[name] = validField;

    let valids = _.clone(this.state.valids);
    valids[name] = validField;
    this.setState({ valids: Object.assign({}, this.state.valids, object), saveDisabled: !this._shouldEnableSave(this.state.signupMode, valids, this.state.agreementChecked) });
  };

  _shouldEnableSave = (signupMode, valids, agreementChecked) => {
    let enableSave;
    if (signupMode) {
      let isValidInputs = _.find(Object.values(valids), (el) => { return el === false; }) === undefined;
      enableSave = isValidInputs && agreementChecked;
    } else {
      enableSave = valids.email && valids.password;
    }
    return enableSave;
  };

  showErrorText = (name) => {
    return !this.state.valids[name] && !this.state.pristines[name];
  }

  //TODO - move this to the API
  loginUser = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    fetch('/users/login', {
      method: 'POST',
      body: data,
    })
      .then(res => {
        if (!res.ok) {
          return Promise.resolve({ ok: false });
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        if (data.ok) {
          window.location.href = `/login?currentUser=${data.jwt}`;
        } else {
          this.setState({ errorMessage: "Password doesn't match the email" });
        }
      });
  };

  //TODO - move this to the API
  submitNewUser = (event) => {
    event.preventDefault();
    let user = {}, formData = new FormData(event.target);
    formData.forEach(function(value, key) {
      user[key] = value;
    });

    const data = { user: user };
    fetch('/users', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (!res.ok) {
          return Promise.resolve({ ok: false });
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        if (data.ok) {
          window.location.href = `/login?currentUser=${data.jwt}`;
        } else {
          this.setState({ errorMessage: "Username is already been taken" });
        }
      });
  };

  render() {
    const isSignup = this.state.signupMode;
    const signText = isSignup ? "Sign up" : "Sign in";
    const inputProps = {
      placeholder: "Password",
      autoFocus: true,
      name: "password",
      autoComplete: "off",
      className: 'form-control',
    };

    return (
      <ReactModal
        isOpen={this.props.show}
        style={customStyles}
        contentLabel="Join our community! Sign in with Twitter."
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={this.props.toggleSignInModal}
        ariaHideApp={false}>
        <i className="fa fa-times pt-3 pr-3 pull-right close-icon" aria-hidden="true" onClick={this.props.toggleSignInModal} />
        <div className="sign-in-modal text-center mt-5">
          <div className="twitter-login-container pl-5 pr-5">
            <h1>
              {this.props.header}
            </h1>
            <img className="logo-icon" src={LogoIcon} />
            <div className="modal-text">
              Join a growing community of managers who share their expectations - so their teammates don't have to do the guesswork!
            </div>

            <div className="twitter-button">
              <a href="/auth/twitter"
                 className="ghost-button ghost-button-twitter twitter-button">
                <img className="twitter-logo" src={TwitterLogoWhiteOnBlue} />
                Sign in <span className="hidden-phone">with Twitter</span>
              </a>
            </div>
            <p className="permission-text">We'll never post without your permission.</p>
          </div>
          <p className="or-seperator pb-2 mb-0">OR</p>

          <div className="email-signup-container" style={{backgroundImage: `url(${modalBgImage})`}}>
            <p className="signup pt-3">{signText}</p>
            <form className="email-signup-form" onSubmit={isSignup ? this.submitNewUser : this.loginUser}>
              {isSignup &&
              <div>
                <CustomInput type="text"
                             name="name"
                             placeholder="Username"
                             showError={this.showErrorText('name')}
                             onChangeFunc={this.validateName}
                             errorText="Username can't be blank"/>
              </div>
              }
              {isSignup &&
              <div>
                <CustomInput type="text"
                  name="display_name"
                  placeholder="Display Name"
                  showError={this.showErrorText('displayName')}
                  onChangeFunc={this.validateDisplayName}
                  errorText="Display name can't be blank"/>
              </div>
              }
              <div>
                <CustomInput type="email"
                             name="email"
                             placeholder="Email"
                             showError={this.showErrorText('email')}
                             onChangeFunc={this.validateEmail}
                             errorText="Email is not a valid email"/>
              </div>
              {!isSignup &&
              <div>
                <CustomInput type="password"
                             name="password"
                             placeholder="Password"
                             showError={this.showErrorText('password')}
                             onChangeFunc={this.validatePassword}
                             errorText="Password name can't be blank"/>
              </div>
              }
              {isSignup &&
              <div>
                <ReactPasswordStrength
                  className="password-strength-input mb-2 mt-1"
                  minLength={5}
                  minScore={2}
                  scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
                  changeCallback={(event) => this.setValidateNewPassword(event)}
                  inputProps={{...inputProps}}
                />
              </div>
              }
              <p className="font-pink mb-1 error-section">{this.state.errorMessage}</p>
              <button className="submit-btn ghost-button ghost-button-medium mt-1" disabled={this.state.saveDisabled}>
                {signText}
              </button>
              {isSignup ?
                <div className="mt-2">
                  <input type="checkbox"
                         defaultValue={false}
                         checked={this.state.agreementChecked}
                         onChange={ this.checkboxClicked }/>
                  <label>I agree to the <a href="/terms" target="_blank">Terms of Service</a></label>
                  <br/>
                  <p className="pb-3 mb-0">
                    Already a member? <span className="font-pink ml-1 mr-1 toggle-sign-in-up" onClick={this.changeToLoginMode}>Sign in</span>
                  </p>
                </div>
                :
                <p className="pt-3 pb-3 mb-0">
                  Not a member? <span className="font-pink ml-1 mr-1 toggle-sign-in-up" onClick={this.changeToSignUpMode}>Sign up</span>
                </p>
              }

            </form>
          </div>
        </div>
      </ReactModal>
    )
  }
}

export default SignInModal;
