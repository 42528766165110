import React from "react";
import AdminReadme from './Admin/Readme';
import Inspiration from './Admin/Inspiration';

export default class Admin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editingReadme: true
    };
  }

  editingReadme = (state) => {
    this.setState({
      editingReadme: state
    });
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 text-center mb-4">
            <a className="mr-3" onClick={() => this.editingReadme(true)}>
              Readme
            </a>
            <a onClick={() => this.editingReadme(false)}>Inspirations</a>
          </div>
        </div>

        {!this.state.editingReadme && <Inspiration {...this.props} />}
        {this.state.editingReadme && (
          <AdminReadme {...this.props} />
        )}
      </div>
    );
  }
}
