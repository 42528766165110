import React, { Component } from 'react';
import CompanyShowcase from "./Community/CompanyShowcase";
import ReadmesIndex from "./Community/ReadmesIndex";

class Community extends Component {
  render() {
    return (
      <div>
        <CompanyShowcase />
        <ReadmesIndex />
      </div>
    )
  }
}

export default Community;
