import React from 'react';
import isMobile from 'ismobilejs';

// Components
import Background from '../../../../assets/images/MRM_features-route.png';
import MobileBackground from '../../../../assets/images/FeaturesBG-Mobile.png';
import Feature1 from '../../../../assets/images/IMG1_features.png';
import Feature2 from '../../../../assets/images/IMG2_features.png';
import Feature3 from '../../../../assets/images/IMG3_features.png';
import Feature4 from '../../../../assets/images/IMG4_features.png';
import bg1 from '../../../../assets/images/bg1.png';
import bg2 from '../../../../assets/images/BG2_features.png';
import PageFooter from './PageFooter';

class Features extends React.Component {
  render() {
    const style = {
      position: 'relative',
      background: `url(${Background}) top 150px center no-repeat`,
      zIndex: '0'
    }

    const mobileStyle = {
      position: 'relative',
      background: `url(${MobileBackground}) top 350px center no-repeat`,
      zIndex: '-2'
    }

    const bg2f = {
      background: `url(${bg2}) bottom no-repeat`,
      backgroundSize: 'cover',
      width: '100%',
      height: '500px'
    }

    return(
      <div style={isMobile.any ? mobileStyle : style} className='feature-page text-center text-lg-left'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-lg-10 ml-auto mr-auto mt-5'>
              <h1>Writing a User Guide for your leadership style should be a reflective process.</h1>
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-6 section-1'>
              <h3>
                <span className='big-number mr-3 d-none d-lg-inline-block'>
                  1
                </span>
                Get the inspiration you need to start
              </h3>

              <h6> Writer's block? We can help!</h6>
              <p>
                Start with some inspiration: Get ideas for which questions to
                answer, and what information you should share about yourself in
                the Readme Editor.
              </p>
            </div>

            <div className='col-lg-6 mt-5'>
              <img src={Feature1} className='w-100'/>
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-10 mr-auto mt-5 d-none d-lg-block'>
              <img className='mt-2' src={Feature2} />
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-6 ml-auto mt-5 mt-lg-0'>
              <h3>
                <span className='big-number mr-3 d-none d-lg-inline-block'>
                  2
                </span>
                Iterate, iterate, iterate
              </h3>

              <h6> Private by default</h6>
              <p>
                Your Readme is private by default. Jot down any thoughts or ideas
                you have now, then revisit and tweak them later, as often as you want.
              </p>
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-10 mr-auto mt-5 mb-5 d-block d-lg-none'>
              <img className='mt-2 w-100' src={Feature2}/>
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-8'>
              <div>
                <img src={bg1} className="bg1f"/>

                <h3 className='font-white bg-header'>
                  Clear expectations say it all
                </h3>

                <p className='bg-body'>
                  Use Manager Readme to reduce ramp-up time as a team so that you <br/>
                  could build great things together sooner.
                </p>

                <p className='bg-body mt-5'>
                  <a href='/edit-readme' className='ghost-button ghost-button-white'>
                    Write your Readme
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className='row section-3'>
            <div className='col-lg-6 section-1'>
              <h3>
                <span className='big-number mr-3 d-none d-lg-inline-block'>
                  3
                </span>
                Get feedback from our Reviewers, your teammates and peers
              </h3>

              <p>
                When you're ready to share your Readme, send people you trust a
                private link and get their input.<br/><br/>

                We make it even easier for you: our pre-written email template
                contains questions you can ask your peers to get constructive
                feedback. Just copy & paste the questions you want and send an
                email to the relevant people.
              </p>
            </div>

            <div className='col-lg-6 mt-5'>
              <img src={Feature3} className='w-100'/>
            </div>
          </div>

          <div className='row section-4'>
            <div className='col-lg-6 mt-5'>
              <img src={Feature4}/>
            </div>

            <div className='col-lg-6 section-42'>
              <h3>
                <span className='big-number mr-3 d-none d-lg-inline-block'>
                  4
                </span>
                Go live!
              </h3>
              <h6>
                Let your Readme speak for itself
              </h6>

              <p>
                Share your Readme with your team, potential<br/>
                employees and our manager community.
              </p>
            </div>
          </div>
        </div>

        <PageFooter />
      </div>
    )
  }
}

export default Features
