import React, { Component } from "react";
import Lockr from "lockr";
import CommentImage from "../../../../../assets/images/MR_comment.svg";
import CommentApi from "../../middleware/commentApi";

class CommentsNumber extends Component {
  state = {
    numOfComments: 0,
    width: window.innerWidth
  };

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  componentDidMount() {
    this.fetchComments();
  }

  render() {
    const { width, numOfComments } = this.state;
    const isMobile = width <= 500;

    return (
      <div>
        <a href="#comments">
          <img
            src={CommentImage}
            className="comment-number-button"
          />

          <span className="comment-number">
            {this.commentsNumber(numOfComments, isMobile)}
          </span>
        </a>
      </div>
    )
  }

  fetchComments = () => {
    const token = Lockr.get('managerReadme:jwt');
    const url = this.props.commentsUrl;

    CommentApi.getNumOfComments(url, token)
      .then(numOfComments => this.setState(numOfComments))
  };

  commentsNumber = (numOfComments, isMobile) => {
    if (numOfComments === 0) {
      if (this.props.currentUserIsOwner) {
        return "No comments yet";
      }

      return isMobile ? "0 comments" : "No comments yet, be the first to provide feedback";
    } else {
      return numOfComments === 1 ? "1 comment" : `${numOfComments} comments`;
    }
  }
}

export default CommentsNumber;
