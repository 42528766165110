import React from "react";

const Logo = ({logoUrl, url}) => {
  if (logoUrl && url) {
    return  (
      <a
        href={url}
        target="_blank"
        className="logo-circle d-flex align-items-center"
      >
        <img className="logo" src={logoUrl} />
      </a>
    )
  }

  if (logoUrl) {
    return (
      <div
        className="logo-circle d-flex align-items-center"
        style={{cursor: "auto"}}
      >
        <img className="logo" src={logoUrl} />
      </div>
    )
  }

  return null;
}

export default Logo;
