import React, { Component } from "react";
import isMobile from 'ismobilejs';
import CopyLinkText from "./CopyLinkText";

class CopyLink extends Component {
  render() {
    if (isMobile.phone) return null;

    return (
      <div
        className={`copy-container ${this.props.containerClass}`}
        onClick={this.props.copyReadmeLink}
      >
        <CopyLinkText
          resetPopups={this.props.resetPopups}
          linkCopiedPopup={this.props.linkCopiedPopup}
          copyLinkText={this.props.copyLinkText}
        />
      </div>
    )
  }
}

export default CopyLink;
