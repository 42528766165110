import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import Snap from './Snap';

document.addEventListener('DOMContentLoaded', () => {
  let mr = document.getElementById('manager-readme');
  let snapshot = document.getElementById('snapshot');

  mr && ReactDOM.render(
    <Routes />, document.getElementById('manager-readme'),
  );

  snapshot && ReactDOM.render(
    <Snap />, document.getElementById('snapshot'),
  );

});
