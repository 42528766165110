import React, { Component, Fragment } from 'react';
import LinkCopied from '../../../../../assets/images/MR_link_copied.png';
import Link from "../../../../../assets/images/MR_link.svg";

class CopyLinkText extends Component {
  state = {
    interval: undefined,
    copyLinkText: 'Copy link'
  };

  componentDidMount() {
    this.setState({
      copyLinkText: this.props.copyLinkText || 'Copy link'
    })
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.linkCopiedPopup && this.props.linkCopiedPopup) {
      const interval = setInterval(this.props.resetPopups, 5000);

      this.setState({ interval })
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  render() {
    if (this.props.linkCopiedPopup) {
      return (
        <Fragment>
          <img src={LinkCopied} className="action-button-icon" /> Link copied!
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <img className="action-button-icon" src={Link} />
          {this.state.copyLinkText}
        </Fragment>
      )
    }
  }
}

export default CopyLinkText;
