import React, { Component, Fragment } from "react";
import ReadmeCard from "../ReadmeCard";

class Readmes extends Component {
  state = {};

  componentDidMount() {
    this.props.fetchReadmes().then(res => {
      if (res.ok) {
        return res.json();
      } else {
        window.location.href = "/not_found"
      }
    })
      .then(data => {
        this.setState({
          readmes: data
        });
      });
  }

  render() {
    const readmes = this.state.readmes;

    if (!readmes) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <i className="fa fa-3x fa-spinner fa-spin" />
            </div>
          </div>
        </div>
      );
    }

    if (readmes.length !== 0) {
      return (
        <Fragment>
          <div className="bg-white">
            <div className="container pt-4 pb-4">
              <div className="title">
                <b>
                  {this.props.title}
                </b>
              </div>
            </div>
          </div>

          <div className="managers">
            <div className="container">
              <div className="row">
                {readmes.map(readme =>
                  <div  key={readme.id} className="col-md-6 col-lg-4 mt-3">
                    <ReadmeCard readme={readme} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Fragment>
      )
    }

    return null;
  }
}

export default Readmes;
