import React from 'react';
import { Link } from 'react-router-dom';
import Typed from 'react-typed';

// Images
import person from '../../../../../assets/images/MR_person_illustration.png';

export default class Pitch extends React.Component {
  render() {
    return(
      <div className='pitch-wrapper'>
        <div className='container pitch text-center text-lg-left'>
          <div className='row'>
            <div className='col-12'>
              <h1>
              Your expectations drive your teammates' behavior.
              </h1>
              <p>
              Write and share your User Guide as a manager so your team can focus on building<br/> 
              great products, having deeper conversations and work towards mutual growth.
              </p>
            </div>
          </div>


          <div className='row'>
            <div className='d-none d-md-inline-block col-lg-6 button-write-readme'>
              <Link to='/edit-readme' className='ghost-button ghost-button-white'>
                Write your Readme &nbsp;&nbsp;✍️
              </Link>

              <p className="mt-2">
                <small className="font-white ml-3">
                  Give it a try, no signup is needed!
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
