import React from "react";
import { Api } from "../middleware/api";
import moment from "moment";
import nextUntil from "../lib/nextUntil.js";
import { Image } from "cloudinary-react";

import mrIcon from "../../../../assets/images/logo-icon.svg";
import profileImage from '../../../../assets/images/default_profile_Image.png';

import LinkableName from "./Company/LinkableName";

class Snapshot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      readme: {},
      user: {}
    };
  }

  componentDidMount() {
    Api.getReadme({ user_id: this.props.match.params.username })
      .then(res => {
        this.setState({
          readme: res.readme,
          user: res.user
        });
      })
      .then(() => {
        this.getSampleText();
      });
  }

  getSampleText = () => {
    let readme = this.state.readme.content;
    let parser = new DOMParser();
    let doc = parser.parseFromString(readme, "text/html");
    let elements = [].slice.call(doc.getElementsByTagName("*")).reverse();
    let pages = [];

    for (var i = elements.length; i--; ) {
      let element = elements[i];

      if (element.nodeName === "H1") {
        let header = element;
        let s = nextUntil(element, "h1");
        let siblings = _.reject(s, sibling => {
          return sibling.localName === "ul";
        });

        let sampleLength = 0;
        var sample = '';

        for(i=0;i < siblings.length;i++) {
          sampleLength += siblings[i].innerHTML.length;
          if(sampleLength < 300) {
            sample = sample + "\r\n" + siblings[i].innerHTML
          } else {
            sample = (sample + "\r\n" + siblings[i].innerHTML).substring(0, 300) + "..."
          }
        }

        this.setState({
          header: element,
          sample: sample
        });

        i = 0;
      }
    }
  };

  render() {
    let userImageStyle = {
      backgroundImage: `url(${this.state.user.profile_image || profileImage})`
    };
    const { user, readme, header, sample } = this.state;

    return (
      <div className="snapshot">
        <div className="stack-and-tiled">
          <div className="stack-1">
            <img className="mr-icon" src={mrIcon} />

            <div className="user-image mr-3" style={userImageStyle} />
            <div className="mt-4 w100">
              <h5 className="user-name mb-0">{user.display_name}</h5>
              <p className="mb-0 user-position">
                {readme.position && `${readme.position}`}{" "}

                <LinkableName company={readme.company} />
              </p>
            </div>

            <div className="mt-4">
              <h1
                className="mb-0"
                dangerouslySetInnerHTML={{
                  __html: header && header.innerText
                }}
              />

              <p
                className="sample"
                dangerouslySetInnerHTML={{
                  __html: sample && sample
                }}
              />

              <p className="created-with float-right">
                {" "}
                Created with <strong>Manager Readme</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Snapshot;
