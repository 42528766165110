import React from 'react';

// Images
import logos from '../../../../../assets/images/MRM_logos.png';

export default class Managers extends React.Component {
  render() {

    return(
      <div className='white-section managers'>
        <div className='container'>
          <div className='row text-center'>
            <div className='col-12'>
              <h3>Managers from these companies shared their Readme</h3>
              <img className="company-badges" src={logos} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
