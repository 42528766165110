import React from 'react';
import Lockr from 'lockr';
import {Api} from '../../middleware/api';
import moment from "moment";

// Images
import filter from '../../../../../assets/images/filters.svg';

// Components
import ReadmeCard from '../ReadmeCard';

class ReadmesIndex extends React.Component {
  constructor(props) {
    super(props);

    const currentUser = Lockr.get("managerReadme:currentUser");
    this.headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token token=${Lockr.get('managerReadme:jwt')}`
    };
    const isAdminOrReviewer = currentUser && (currentUser.data.is_admin || currentUser.data.reviewer);
    this.baseUrl = '/readmes';
    this.isLoading = false;
    this.timestamps = [0];
    this.state = {
      currentUser: currentUser,
      readmes: [],
      experienceFilter: [],
      typeFilter: [],
      showFilters: false,
      isAdminOrReviewer: isAdminOrReviewer,
      publicOnly: !isAdminOrReviewer
    };
  }

  componentDidMount() {
    this.loadReadmes(this.baseUrl);
  }

  componentDidUpdate() {
    if (!this.isLoading) {
      return;
    }
    let url = this.baseUrl;
    const timestamp = this.state.timestamp;
    let queryParams = `po=${this.state.publicOnly}`;
    _.each(this.state.experienceFilter, (filter) => { queryParams = `${queryParams}&experience[]=${encodeURIComponent(filter)}`; });
    _.each(this.state.typeFilter, (filter) => { queryParams = `${queryParams}&manager_type[]=${encodeURIComponent(filter)}`; });
    if (timestamp) {
      queryParams = `${queryParams}&timestamp=${timestamp}`;
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.loadReadmes(`${url}?${queryParams}`);
  }

  loadReadmes(url) {
    fetch(url, {
      headers: this.headers
    }).then((res) => {
      if (res.ok) {
        return res.json()
      } else {
        Api.logOut();
      }
    })
      .then((data) => {
        this.isLoading = false;
        const readmes = data.readmes;
        const showNext = data.has_next;
        const timestampsEmpty = this.timestamps.length === 1;
        if (_.isEmpty(readmes)) {
          this.setState({
            timestamp: this.timestamps.pop(),
            showNext: false,
            showPrev: true,
            disableNext: false,
            disablePrev: false
          });
          return;
        }
        this.setState({
          readmes: readmes,
          timestamp: moment(_.minBy(readmes, 'updated_at').updated_at).unix(),
          showNext: showNext,
          showPrev: !timestampsEmpty,
          disableNext: false,
          disablePrev: false
        });
      })
      .catch(() => Api.logOut());
  };

  nextPage() {
    this.isLoading = true;
    this.timestamps.push(this.state.timestamp);
    this.setState({
      disableNext: true
    });
  }

  prevPage() {
    this.isLoading = true;
    this.timestamps.pop();
    const timestamp = _.last(this.timestamps);
    this.setState({
      disablePrev: true,
      timestamp: timestamp || 0
    });
  }

  filterExperience = (years) => {
    let experienceFilter = this.state.experienceFilter;

    if (this.contains(experienceFilter, years)) {
      this.remove(experienceFilter, years);
    } else {
      this.add(experienceFilter, years);
    }
    this.isLoading = true;
    this.timestamps = [0];
    this.setState({
      experienceFilter: experienceFilter,
      timestamp: undefined
    });
  };

  filterType = (type) => {
    let typeFilter = this.state.typeFilter;

    if (this.contains(typeFilter, type)) {
      this.remove(typeFilter, type);
    } else {
      this.add(typeFilter, type);
    }

    this.isLoading = true;
    this.timestamps = [0];
    this.setState({
      typeFilter: typeFilter,
      timestamp: undefined
    });
  };

  toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters
    });
  };

  onlyPublic = () => {
    this.isLoading = true;
    this.timestamps = [0];
    this.setState({
      publicOnly: true,
      timestamp: undefined
    });
  };

  add = (array, value) => {
    if (array.indexOf(value) === -1) {
      array.push(value);
    }
  };

  remove = (array, value) => {
    const index = array.indexOf(value);
    if (index !== -1) {
      array.splice(index, 1);
    }
  };

  contains = (array, value) => {
    return (array.indexOf(value) > -1);
  };

  render() {
    return(
      <div className='readmes-index light-grey-section'>
        <div className='container-fluid white-section'>
          <div className='row'>
            <div className='col-12'>
              {!this.state.isAdminOrReviewer &&
              <h2 className='text-center'>
                Recently shared Readmes in our community
              </h2>
              }
              {this.state.isAdminOrReviewer &&
              <div>
                <h2 className='text-center'>
                  Admin and Reviewers will see in this page
                  <br/>
                  both public and private Readmes.
                  <br/>
                </h2>
                <h4 className='text-center mt-4'>
                  To see only public Readmes please click <a onClick={this.onlyPublic}>here</a>
                </h4>
              </div>
              }
            </div>
          </div>

          <div className='filters-wrapper'>
            <div className='container filters'>
              <div className='row'>
                <div className='col-12'>
                  <div className='action-buttons'>

                    <ul className='d-flex justify-content-center d-lg-none'>
                      <li className='filter-results' onClick={this.toggleFilters}>
                        <span><img src={filter} /> FILTER RESULTS </span>
                      </li>
                    </ul>

                    {this.state.showFilters && <div className='mobile-filters d-inline d-lg-none'>
                      <ul>
                        <li className='label mb-4 d-flex align-items-center justify-content-between'>
                          FILTER BY: <i onClick={this.toggleFilters} className='fa fa-close d-flex justify-content-end'></i>
                        </li>

                        <li className='mb-2'>
                          <span className='mr-2'>MANAGERIAL EXPERIENCE</span><br/>
                          <button className={this.contains(this.state.experienceFilter, '0-2') ? 'gradient-button' : 'ghost-button ghost-button-grey'} onClick={() => this.filterExperience('0-2')}>
                            0-2 YEARS
                          </button>

                          <button className={this.contains(this.state.experienceFilter, '3-8') ? 'gradient-button' : 'ghost-button ghost-button-grey'} onClick={() => this.filterExperience('3-8')}>
                            3-8 YEARS
                          </button>

                          <button className={this.contains(this.state.experienceFilter, '8+') ? 'gradient-button' : 'ghost-button ghost-button-grey'} onClick={() => this.filterExperience('8+')}>
                            8+ YEARS
                          </button>
                        </li>

                        <li>
                          <span className='mr-2'>TYPE</span><br/>
                          <button className={this.contains(this.state.typeFilter, 'M') ? 'gradient-button' : 'ghost-button ghost-button-grey'} onClick={() => this.filterType('M')}>
                            MANAGER
                          </button>

                          <button className={this.contains(this.state.typeFilter, 'M.O.M.') ? 'gradient-button' : 'ghost-button ghost-button-grey'} onClick={() => this.filterType('M.O.M.')}>
                            MANAGER OF MANAGER
                          </button>
                        </li>
                      </ul>
                    </div>}

                    <ul className='d-none d-lg-inline'>
                      <li className='label br-no-line'>
                        FILTER BY:
                      </li>

                      <li className='br-no-line'>
                        <span className='mr-2'>MANAGERIAL EXPERIENCE</span>
                        <button className={this.contains(this.state.experienceFilter, '0-2') ? 'gradient-button' : 'ghost-button ghost-button-grey'} onClick={() => this.filterExperience('0-2')}>
                          0-2 YEARS
                        </button>

                        <button className={this.contains(this.state.experienceFilter, '3-8') ? 'gradient-button' : 'ghost-button ghost-button-grey'} onClick={() => this.filterExperience('3-8')}>
                          3-8 YEARS
                        </button>

                        <button className={this.contains(this.state.experienceFilter, '8+') ? 'gradient-button' : 'ghost-button ghost-button-grey'} onClick={() => this.filterExperience('8+')}>
                          8+ YEARS
                        </button>
                      </li>

                      <li className='br-no-line'>
                        <span className='mr-2'>TYPE</span>
                        <button className={this.contains(this.state.typeFilter, 'M') ? 'gradient-button' : 'ghost-button ghost-button-grey'} onClick={() => this.filterType('M')}>
                          MANAGER
                        </button>

                        <button className={this.contains(this.state.typeFilter, 'M.O.M.') ? 'gradient-button' : 'ghost-button ghost-button-grey'} onClick={() => this.filterType('M.O.M.')}>
                          MANAGER OF MANAGER
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className='container'>
          <div className='row'>
            {
              _.orderBy(this.state.readmes, 'updated_at', 'desc').map((readme) => {
                return <div  key={readme.id} className='col-md-6 col-lg-4 mt-3'>
                  <ReadmeCard {...this.props} readme={readme}/>
                </div>
              })
            }
          </div>
          <div className='row' style={{ position: 'relative', width: '226px', margin: '47px auto 0 auto' }}>
            {this.state.showPrev &&
            <button
              disabled={this.state.disablePrev}
              onClick={this.prevPage.bind(this)}
              className="ghost-button ghost-button-small">
              <span>Prev Page</span>
            </button>
            }
            {this.state.showNext &&
            <button style={{ position: 'absolute', right: '0' }}
                    disabled={this.state.disableNext}
                    onClick={this.nextPage.bind(this)}
                    className="ghost-button ghost-button-small">
              <span>Next Page</span>
            </button>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default ReadmesIndex
