import React from 'react';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import Lockr from 'lockr';

import backgroundImage from '../../../../../assets/images/HPB.png';

import ReadmeCard from '../ReadmeCard';
import isMobile from 'ismobilejs';

export default class SharedReadme extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      readmes: []
    }
  }

  componentWillMount() {
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token token=${Lockr.get('managerReadme:jwt')}`
    }

    fetch('/readmes_landing', {
      headers: headers
    }).then((res) => {
      if(res.ok) {
        return res.json()
      }
    })
    .then((data) => {
      this.setState({
        readmes: data
      });
    });
  }

  render() {
    let readme = this.state.readmes[0]

    return(
      <div className="shared-readme">
        <img className="background-image" src={backgroundImage}></img>

        <div className="pink-section">
          <div className='container'>
            <div className='row'>
              <div className='col-12 col-lg-8 ml-auto mr-auto'>
                <h3 className='text-center font-white'>
                  Looking for some inspiration?
                </h3>
                <h3 className='text-center mb-5 font-white'>
                  These Readmes shared in our community
                </h3>
              </div>
            </div>

            <div className='row mt-3'>
              {
                !isMobile.any && _.map(this.state.readmes, (readme, index) => {
                  return <div key={index} className='col-md-6 col-lg-4'>
                    <ReadmeCard {...this.props} readme={readme}/>
                  </div>
                })
              }

              {
                isMobile.any && readme &&
                <div className='col-11 ml-auto mr-auto'>
                  <ReadmeCard {...this.props} readme={readme}/>
                </div>
              }
            </div>

            <div className='row'>
              <div className='col-12 text-center mt-5'>
                <p className='mb-4 pb-5'>
                  <a className='ghost-button ghost-button-white mt-5' href='/community' >
                    See more Readmes
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
