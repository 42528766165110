import React from 'react';

// Components
import Background from '../../../../assets/images/MRM_features-route.png';
import Feature1 from '../../../../assets/images/IMG1_usecases_preview.png';
import Feature2 from '../../../../assets/images/IMG2-temp_usecases.png';
import Feature3 from '../../../../assets/images/IMG3_features.png';
import Feature4 from '../../../../assets/images/IMG4_features.png';
import bg1 from '../../../../assets/images/bg1.png';
import bg2 from '../../../../assets/images/BG2_features.png';

import PageFooter from './PageFooter';

class UseCases extends React.Component {
  render() {
    const bg2f = {
      background: `url(${bg2}) bottom no-repeat`,
      backgroundSize: 'cover',
      width: '100%',
      height: '500px'
    }

    return(
      <div className='use-cases'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-lg-10 text-center text-lg-left mt-5'>
              <h1>
                Use your Readme to promote deeper and more engaging conversations.
              </h1>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 text-center text-lg-left section-1'>
              <h6>
                Reduce ramp-up time during employee onboarding by sharing how
                you work, and guiding them on where to focus in their first 90 days.
              </h6>

              <p>
                Let new members of your team read your Readme when they
                arrive. On your first 1:1 with them, clarify any open issue that
                it brought up, explain the motivation behind some of your
                philosophy and let them ask you questions.
              </p>

              <p>
                Explain where they should focus on their first 90 days at the
                team, e.g. what would be an incredible outcome that should set
                them on a path to success for the long run?
              </p>

              <p>
                Having explicit expectations shared from the get-go allows you
                to switch gears and dive into deeper conversations. It opens up
                the opportunity for the other side to share how they prefer to
                work and then how you can utilize each other's strengths for
                mutual growth.
              </p>
            </div>
          </div>

          <div className='row text-center text-lg-left section-1'>
            <div className='col-md-6 ml-auto'>
              <h5>
                Move aside guessing and implicit understanding. Make your 1:1s
                focused on mutual growth and better relationships.
              </h5>

              <p>
                The reality is we all could use some level of assurance,
                regardless of how well we think we read people.
              </p>

              <p>
                Use your Readme to hold others accountable for behaviors
                you'd like to see more (or less) of. Let them hold you accountable
                for your promises, as a way to build a safer environment where
                hard conversations can take place. Figure out how to complement
                each other by being open about your strengths and weaknesses.
              </p>

              <p>
                For future Engineering Manager, such document can lead to them
                thinking of writing their own version of Manager Readme.
                Encourage people to write more; it scales much better than
                keeping it in their head.
              </p>
            </div>

            <div className='col-md-6 '>
              <img src={Feature1} />
            </div>
          </div>

          <div className='row text-center text-lg-left section-3'>
            <div className='col-md-6 section-1 ml-auto'>
              <img src={Feature2} />
            </div>

            <div className='col-md-6 mt-5 pt-5'>
              <h5 className='mt-5'>Replace the boring "(We're hiring!)" addition in your
                LinkedIn title, with something more intriguing about who you
                are and how you work.
              </h5>

              <p>
                We see it all the time, everyone are looking for people to hire.
                When people view your profile, what would you like them to know
                about joining your team or working with you? How can you provide
                more personal view into the challenges and culture in your company?
              </p>

              <p>
                Sharing your Readme can provide that:<br/>
                1. Use it for your title.<br/>
                2. Add it as a new Experience, so your network will know about it.<br/>
              </p>
            </div>
          </div>

          <div className='row text-center text-lg-left section-4'>
            <div className='col-md-6 mt-5 pt-5'>
              <h5 className='mt-5'>
                For Public Speaking
              </h5>

              <p>
                Stand out and instead of using the regular Twitter handle and
                email address in your presentation, add a link to your Manager
                Readme. Let people check it out after you talk and get to know
                you a bit better.
              </p>

            </div>
          </div>
        </div>

        <PageFooter />
      </div>
    )
  }
}

export default UseCases
