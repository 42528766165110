import React, { Component } from "react";
import Readmes from "./Company/Readmes";
import Links from "./Company/Links";
import Logo from "./Company/Logo";
import Jobs from "./Company/Jobs";
import Lockr from "lockr";

class Company extends Component {
  state = {
    slug: this.props.match.params.slug.toLowerCase()
  };

  componentDidMount() {
    this.fetchCompany()
  }

  render() {
    const company = this.state.company;

    if (!company) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <i className="fa fa-3x fa-spinner fa-spin" />
            </div>
          </div>
        </div>
      );
    }

    const logoUrl = company.logo;
    const companyNameClass = logoUrl ?
      "d-flex align-items-center" :
      "d-flex justify-content-around"
    const backgroundUrl = company.background;
    const headlineStyle = backgroundUrl ? {
      background: "no-repeat center",
      backgroundImage: `url(${backgroundUrl})`
    } : null;
    const slug = this.state.slug;

    return (
      <div className="company">
        <div className="headline" style={headlineStyle}>
          <div className="container">
            <div className={companyNameClass}>
              <Logo
                logoUrl={logoUrl}
                url={company.url}
              />

              <h1 className="name">
                <b>{company.name}</b>
              </h1>
            </div>
          </div>
        </div>

        {company.description &&
          <div className="container mt-5">
            <div className="title">
              <b>Managers at {company.name}</b>
            </div>

            <div className="description">
              {company.description}
            </div>
          </div>
        }

        <Links
          url={company.url}
          twitterUrl={company.twitter_url}
          facebookUrl={company.facebook_url}
          angellistUrl={company.angellist_url}
          linkedinUrl={company.linkedin_url}
        />

        <Jobs
          slug={company.slug}
        />

      <Readmes fetchReadmes={this.fetchReadmes} title="Read our Readmes to learn more about how we work:"/>
    </div>
    )
  }

  fetchCompany = () => {
    const slug = this.state.slug;
    const url = `/companies/${slug}`;

    fetch(url)
      .then(res => {
        if (res.ok) {
          return res.json();
        } else {
          window.location.href = "/not_found"
        }
      })
      .then(data => {
        if (data.error) {
          window.location.href = "/not_found";
        } else {
          this.setState({ company: data })
        }
      })
  };

  fetchReadmes = () => {
    const url = `/companies/${this.state.slug}/readmes`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token token=${Lockr.get('managerReadme:jwt')}`
    };
    return fetch(url, { headers: headers });
  };
}

export default Company;
