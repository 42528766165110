import React from 'react';
import onClickOutside from 'react-onclickoutside';
import {Api} from '../../middleware/api';
import Lockr from 'lockr';

class CollectFeedback extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      revieweRequested: false,
    }
  }

  componentDidMount() {
    Api.getReviewRequest(Lockr.get("managerReadme:jwt")).then(res => {
      if (res) {
        this.setState({
          reviewRequested: true,
        })
      } else {
      }
    });
  }

  handleClickOutside(evt) {
    this.props.resetPopups();
  }

  requestReview = () => {
    Api.createReviewRequest(Lockr.get("managerReadme:jwt")).then(() => {
      this.setState({
        reviewRequested: true
      })
    })
  }

  emailCopy = () => {
    let currentUser = this.props.currentUser;
    let textField = document.createElement('textarea');

    if(currentUser.readme_private) {
      var url = `https://${window.location.host}/readme/${currentUser.data.name}/${currentUser.data.code}`;
    } else {
      url = `https://${window.location.host}/readme/${currentUser.data.name}`;
    }

    return `Hey,

I've created a document (aka Manager Readme) which represents my leadership style as a manager. I was wondering if you could read it and provide me with some feedback around:

- Is that a good representation of my leadership style?
- Which parts do I need to elaborate on more?
- What else did I miss?

Please have a look here and give me your honest feedback:
${url}
`
  }

  render() {
    return(
      <div>
        <h5> Ready to receive feedback on your writing? </h5>

        <h6>Receive feedback from Manager Readme Reviewers</h6>
        {!this.state.reviewRequested && <span><p>
          Manager Readme Reviewers are part of our community, with a goal to
          help others improve and clarify their writing. They will review your
          Readme, and provide you with suggestions and tips.
        </p>

        <button className="ghost-button mb-3" onClick={this.requestReview}>
          Yes, I'd like #MR Reviewers to review my writing
        </button></span>}

        {this.state.reviewRequested && <span><p>
          Thank you, our Reviewers will go over it shortly and provide you with
          feedback (via email). You can also use the instructions below to start
          collecting feedback from people who know you.
        </p></span>}

        <h6>Receive feedback from people who know you</h6>
        <p> You can share your Readme with others who know you
          best. Ask them to help you iterate on your writing so far.
        </p>

        <h6>We tried to make it easy to collect feedback, so we generated the email for you:</h6>
        <textarea rows={15} id='feedback' className='mb-3' value={this.emailCopy()} readOnly/>
        <a href='#' onClick={this.props.copyEmailToClipboard}>{this.props.feedbackText}</a>
        {this.props.feedbackText === 'Email copied!' && <a href='#' className='pull-right' onClick={this.props.toggleFeedback}>OK</a>}
      </div>
    )
  }
}

export default onClickOutside(CollectFeedback)
