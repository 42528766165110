import React from 'react';

// Components
import ReadmeCard from './ReadmeCard';
import Lockr from "lockr";
import {Api} from "../middleware/api";

class Reviewers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      readmes: []
    }
  }

  componentDidMount() {
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token token=${Lockr.get('managerReadme:jwt')}`
    };

    fetch('/readmes/reviewers', {
      headers: headers
    }).then((res) => {
      if (res.ok) {
        return res.json()
      } else {
        Api.logOut();
      }
    }).then((data) => {
      this.setState({
        readmes: data
      });
    });
  }

  render() {
    return(
      <div className='reviewers'>
        <div className='container-fluid'>
          <div className="container">
            <div className='row'>
              <div className='col-12 col-lg-10 text-center text-lg-left mt-5'>
                <h1>
                  Meet our Reviewers 👋
                </h1>
              </div>
            </div>

            <div className='row'>
              <div className='col-12 text-center text-lg-left section-1'>
                <p>
                  Manager Readme Reviewers are part of our community, with a goal to help others improve and clarify their writing.
                  They will review your Readme if you ask for it (Click on "Collect Feedback" in your Readme page), and
                  provide you with suggestions and tips.
                </p>
              </div>
            </div>

            <div className='row'>
              <div className='col-12 text-center text-lg-left section-1'>
                <br/>
                <div className='container light-grey-section'>
                  <div className='row'>
                    {
                      _.orderBy(this.state.readmes, 'updated_at', 'desc').map((readme) => {
                        return <div  key={readme.id}  className='col-md-6 col-lg-4 mt-3'>
                          <ReadmeCard {...this.props} readme={readme}/>
                        </div>
                      })
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-12 text-center text-lg-left section-1'>
                <br/><br/>
                <h5>
                  Want to join our Reviewers team?
                </h5>
                <p>
                  We'd love to have you! Send an email to oren@managerreadme.com with some details about you, and your username or the email you used on the site.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Reviewers;
