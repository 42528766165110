import React from "react";
import Lockr from "lockr";
import { Api } from "../../middleware/api";
import R from "../../lib/redactor.js";

export default class Inspiration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      topics: [],
      answers: [],
      showInspirationForm: false
    };
  }

  componentDidMount() {
    Api.getTopics(Lockr.get("managerReadme:jwt")).then(res => {
      this.setState({
        topics: res
      });
    });
  }

  toggleInspirationForm = () => {
    this.setState({
      showInspirationForm: !this.state.showInspirationForm
    });
  };

  updateTitle = event => {
    if(this.state.editing) {
      let topic = this.state.editing;
      topic.title = event.target.value;

      this.setState({
        editing: topic
      });
    } else {
      this.setState({
        title: event.target.value
      });
    }
  };

  addAnswer = () => {
    if(this.state.editing) {
      let topic = this.state.editing;
      topic.answers.push({ content: "" });

      this.setState({
          editing: topic
      },() => {
        this.renderRedactor(`answer-${topic.answers.length - 1}`);
      }
      );
    } else {
      let answers = this.state.answers;
      answers.push({ content: "" });

      this.setState(
        {
          answers: answers
        },
        () => {
          this.renderRedactor(`answer-${answers.length - 1}`);
        }
      );
    }

  };

  renderRedactor = el => {
    let element = document.getElementById(el);
    $R(element, {
      buttons: [
        "html",
        "format",
        "bold",
        "italic",
        "ul",
        "link",
        "redo",
        "undo",
        "deleted"
      ],
      formatting: ["p", "blockquote", "h1", "h2", "pre"],
      plugins: ["alignment"],
      toolbarFixedTopOffset: 80,
      callbacks: {
        keydown: e => {
          let newContent = $R(element, "source.getCode");
          this.updateAnswer(newContent, element);
        },
        blur: e => {
          let newContent = $R(element, "source.getCode");
          this.updateAnswer(newContent, element);
        }
      }
    });

    $R(element);
  };

  removeAnswer = (answerIndex) => {
    if(this.state.editing) {

      let topic = this.state.editing;
      Api.deleteAnswer(topic.answers[answerIndex].id, Lockr.get("managerReadme:jwt"))

      topic.answers.splice(answerIndex,1);

      this.setState({
        editing: topic
      });

    } else {
      let answers = this.state.answers;

      answers.splice(answerIndex,1);

      this.setState({
        answers: answers
      });
    }
  }

  updateAnswer = (content, element) => {
    if(this.state.editing) {
      let topic = this.state.editing;
      let eId = element.id;

      let answer = topic.answers[eId.split('-')[1]];
      answer.content = content;

      this.setState({
        editing: topic
      });
    } else {
      let answers = this.state.answers;
      let eId = element.id
      answers[eId.split('-')[1]].content = content;

      this.setState({
        answers: answers
      });
    }
  };

  saveTopic = () => {
    if(this.state.editing) {
      let payload = {
        topic: this.state.editing
      }

      Api.updateTopic(payload, Lockr.get("managerReadme:jwt")).then((res) => {
        window.location.reload();
      });
    } else {
      let title = this.state.title;
      let answers = this.state.answers;
      let payload = {
        title: title,
        answers: answers
      }

      Api.saveTopic(payload, Lockr.get("managerReadme:jwt")).then(() => {
        window.location.reload();
      });
    }

  }

  cancel = () => {
    this.setState({
      showInspirationForm: false,
      editing: null
    });
  }

  deleteTopic = (topic_id) => {
    Api.deleteTopic(topic_id, Lockr.get("managerReadme:jwt")).then(() => {
      Api.getTopics(Lockr.get("managerReadme:jwt")).then(res => {
        this.setState({
          topics: res
        });
      });
    });
  }

  editTopic = (topic_id) => {
    let topics = this.state.topics;
    let topic = _.find(topics, {id: topic_id});

    this.setState({
      editing: topic,
    }, () => {
      _.forEach(topic.answers, (answer, index) => {
        this.renderRedactor(`answer-${index}`);
        $R(`#answer-${index}`, "source.setCode", answer.content);
      })
    });
  }

  render() {
    return (
      <div className="container inspiration-index">
        {this.state.showInspirationForm && (
          <div className="inspiration-form">
            <div className="row">
              <div className="col-md-10 col-lg-8 ml-auto mr-auto mt-5">
                <div className="inspiration-form-header">
                  <label className="mr-2">Title</label>
                  <input type="text" onChange={this.updateTitle} />
                  <span
                    className="font-white float-right mt-2"
                    onClick={this.cancel}
                  >
                    <i className="fa fa-close" /> Cancel
                  </span>
                  <span
                    className="font-white float-right mr-3 mt-2"
                    onClick={this.saveTopic}
                  >
                    <i className="fa fa-plus" /> Save
                  </span>
                  <span
                    className="font-white float-right mr-3 mt-2"
                    onClick={this.addAnswer}
                  >
                    <i className="fa fa-plus" /> Add Answer
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-10 col-lg-8 ml-auto mr-auto">
                <div className="inspiration-form-answers">
                  {_.map(this.state.answers, (answer, index) => {
                    return (
                      <div key={index}>
                        <h5>
                          Answer {index + 1}{" "}
                          <i
                            className="fa fa-trash-o"
                            onClick={() => this.removeAnswer(index)}
                          />
                        </h5>
                        <div className='r' id={`answer-${index}`} />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.editing && (
          <div className="inspiration-form">
            <div className="row">
              <div className="col-md-10 col-lg-8 ml-auto mr-auto mt-5">
                <div className="inspiration-form-header">
                  <label className="mr-2">Title</label>
                  <input type="text" defaultValue={this.state.editing.title} onChange={this.updateTitle} />
                  <span
                    className="font-white float-right mt-2"
                    onClick={this.cancel}
                  >
                    <i className="fa fa-close" /> Cancel
                  </span>
                  <span
                    className="font-white float-right mr-3 mt-2"
                    onClick={this.saveTopic}
                  >
                    <i className="fa fa-plus" /> Save
                  </span>
                  <span
                    className="font-white float-right mr-3 mt-2"
                    onClick={this.addAnswer}
                  >
                    <i className="fa fa-plus" /> Add Answer
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-10 col-lg-8 ml-auto mr-auto">
                <div className="inspiration-form-answers">
                  {_.map(this.state.editing && this.state.editing.answers, (answer, index) => {
                    return (
                      <div key={index}>
                        <h5>
                          Answer {index + 1}{" "}
                          <i
                            className="fa fa-trash-o"
                            onClick={() => this.removeAnswer(index)}
                          />
                        </h5>
                        <div className='r' id={`answer-${index}`} />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-10 ml-auto mr-auto mb-4">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Title</th>
                  <th scope="col">
                    <i
                      className="fa fa-plus"
                      onClick={this.toggleInspirationForm}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {_.map(this.state.topics, topic => {
                  return (
                    <tr key={topic.id}>
                      <th scope="row">{topic.id}</th>
                      <td>{topic.title}</td>
                      <td>
                        <i className="fa fa-edit mr-1" onClick={() => this.editTopic(topic.id)}/>
                        <i className="fa fa-trash-o" onClick={() => this.deleteTopic(topic.id)}/>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
