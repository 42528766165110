import React, { Component } from "react";
import Comment from "./Comments/Comment";
import NewComment from "./Comments/NewComment";
import FounderComment from "./Comments/FounderComment";
import Lockr from 'lockr';
import SignInModal from "./SignInModal";

import CommentApi from "../middleware/commentApi";

class Comments extends Component {
  constructor() {
    super();

    this.state = {
      comments: undefined,
      currentUser: Lockr.get("managerReadme:currentUser"),
      showModal: false
    };

    this.fetchComments = this.fetchComments.bind(this);
    this.toggleSignInModal = this.toggleSignInModal.bind(this);
  }

  componentDidMount() {
    this.fetchComments();
    if (location.hash === "#comments") {
      let element = document.getElementById('comments');
      element.scrollIntoView({block: "end", behavior: "smooth"});
    }
  }

  render() {
    const { comments, currentUser, showModal } = this.state;

    return (
      <div id="comments" className="comments">
        {!currentUser &&
          <SignInModal
            toggleSignInModal={this.toggleSignInModal}
            show={showModal}
            header={"Join our community!"}
          />
        }

        <NewComment
          ownerName={this.props.ownerName}
          ownerId={this.props.ownerId}
          commentsUrl={this.props.commentsUrl}
          fetchComments={this.fetchComments}
          readmeId={this.props.readmeId}
          toggleSignInModal={this.toggleSignInModal}
        />

        <FounderComment
          showModal={showModal}
          toggleSignInModal={this.toggleSignInModal}
        />

        {comments &&
          comments.map(comment =>
            <Comment
              key={comment.id}
              commentId={comment.id}
              body={comment.body}
              replies={comment.replies}
              hearts={comment.hearts}
              isPrivate={comment.private}
              updatedAt={comment.updated_at}
              user={comment.user}
              commentsUrl={`/comments/${comment.id}/comments`}
              fetchComments={this.fetchComments}
              readmeId={this.props.readmeId}
              toggleSignInModal={this.toggleSignInModal}
            />
          )
        }
      </div>
    )
  }

  fetchComments() {
    const token = Lockr.get('managerReadme:jwt');
    const url = this.props.commentsUrl;
    const showAllComments = this.props.showAllComments;

    CommentApi.getComments(url, token, showAllComments)
      .then(rawComments => {
        const comments = this.formatComments(rawComments);

        this.setState({ comments })
      })
  }

  formatComments(comments) {
    // filter out comments that were made directly on the README
    const topLevelComments = comments.filter(comment =>
      comment.commentable_type === "Readme"
    )

    // connect comments that were made as replies to other comments
    const commentsOfComments = comments
      .filter(comment =>
        comment.commentable_type === "Comment"
      )
      .map(comment => {
        // find children
        const replies = comments.filter(com =>
          com.commentable_type === "Comment" &&
          com.commentable_id === comment.id
        );

        comment.replies = replies;

        return comment
    });

    // finally, connect top level commands with their replies
    return topLevelComments.map(topCom => {
      const replies = commentsOfComments
        .filter(comOfCom => comOfCom.commentable_id === topCom.id);

      topCom.replies = replies;

      return topCom
    })
  }

  toggleSignInModal() {
    this.setState(prevState => this.setState({ showModal: !prevState.showModal }))
  }
}

export default Comments;
