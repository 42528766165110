import React from 'react';
import 'isomorphic-fetch'
import 'es6-promises'
import queryString from 'query-string';
import Lockr from 'lockr';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

// Components

class Login extends React.Component {
  componentDidMount() {
    let parsedUrl = queryString.parse(window.location.search);
    let encodedUser = parsedUrl.currentUser;
    let decodedUser = jwtDecode(encodedUser);

    Lockr.set('managerReadme:jwt', encodedUser);
    Lockr.set('managerReadme:currentUser', decodedUser);

    let user = decodedUser.data;
    if (user.first_login)
      window.location.href = '/edit-readme';
    else {
      if (decodedUser.readme_private) {
        window.location.href = `/readme/${user.name}/${user.code}`;
      } else {
        window.location.href = `/readme/${user.name}`;
      }
    }
  }

  render() {
    return(
      <div className='login'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mt-5 text-center'>
              <h1>
                <i className='fa fa-spinner fa-spin mr-3'/>
                Logging you in one moment please
              </h1>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Login
