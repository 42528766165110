import React from "react";
import Lockr from "lockr";
import moment from "moment";
import nextUntil from "../lib/nextUntil.js";

// Components
import LinkableName from "./Company/LinkableName";

// Images
import Fingers from "../../../../assets/images/MR_fingers.svg";
import privateIcon from "../../../../assets/images/MR_lock.svg";
import CommentImage from "../../../../assets/images/MR_comment.svg";

class ReadmeCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {}
    };
  }

  componentDidMount() {
    this.getSampleText();

    let headers = {
      "Content-Type": "application/json",
      Authorization: `Token token=${Lockr.get("managerReadme:jwt")}`
    };

    fetch(`/users/${this.props.readme.user_id}`, {
      headers: headers
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        this.setState({
          user: data.user,
          readmeUrl: this.props.readme.private ? `/readme/${data.user.name}/${data.user.code}` : `/readme/${data.user.name}`,
          privateReadme: this.props.readme.private,
          numOfComments: data.num_of_comments > 0 ? data.num_of_comments : undefined
        });
      });
  }

  viewReadme = () => {
    window.location.href = this.state.readmeUrl;
  };

  viewReadmeComments = () => {
    window.location.href = this.state.readmeUrl + '#comments';
  };

  getSampleText = () => {
    let readme = this.props.readme.content;
    let parser = new DOMParser();
    let doc = parser.parseFromString(readme, "text/html");
    let elements = [].slice.call(doc.getElementsByTagName("*")).reverse();
    let pages = [];

    for (var i = elements.length; i--; ) {
      let element = elements[i];

      if (element.nodeName === "H1") {
        let header = element;
        let siblings = nextUntil(element, "h1");

        if (siblings[0] && siblings[0].innerHTML.length > 180) {
          this.setState({
            header: element,
            sample: siblings[0].innerHTML.substring(0, 180) + "..."
          });
        } else if (siblings[0]) {
          this.setState({
            header: element,
            sample: siblings[0].innerHTML
          });
        } else {
          this.setState({
            header: element,
            sample: ""
          });
        }

        i = 0;
      }
    }
  };

  imageFailedToLoad = (imageUrl) => {
    fetch(`/images/broken_image?profile_image=${imageUrl}`).then(res => {});
  };

  render() {
    const readme = this.props.readme;
    let userImageStyle = {
      backgroundImage: this.state.user.profile_image
        ? "url(" + this.state.user.profile_image + ")"
        : "",
      borderRadius: "50%"
    };

    return (
      <div className="readme-card">
        <div className="readme-card-header">
          {this.state.user.reviewer &&
            <div className="reviewer-badge-absolute" >
              REVIEWER
              <div className="relative">
                <img className="fingers" src={Fingers} />
              </div>

              <div className="tt">
                Manager Readme Reviewers are part of our
                community, with a goal to help others improve
                and clarify their writing. They will review your
                Readme if you ask for it (Click on "Collect
                Feedback" in your Readme page), and
                provide you with suggestions and tips.
              </div>
            </div>
          }

          <div className="row">
            <div className="col-12">
              <div
                className="user-image"
                style={userImageStyle}
                className="user-image"
              />
              <img src={this.state.user.profile_image} onError={(e)=>{this.imageFailedToLoad(e.target.src)}} style={{ display: 'none' }}/>

              <p className="user-name mb-0 mt-2">{this.state.user.display_name}</p>
              <p className="position mb-0">
                {readme.position && `${readme.position}`}{" "}
                <LinkableName company={readme.company} />
              </p>
              <p>
                <small>
                  Last Updated:{" "}
                  {moment(readme.updated_at).format("MMMM DD, YYYY")}
                </small>
              </p>
            </div>

            <div className="col-12 text-center experience mt-1">
              <div className="row">
                <div className="col-6">
                  <strong>{readme.experience}</strong>
                  <p>Years of managerial experience</p>
                </div>

                <div className="col-6">
                  <strong>{readme.manager_type}</strong>
                  <p>
                    {readme.manager_type === "M"
                      ? "Manager"
                      : "Manager of Managers"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="readme-card-body">
          <div className="row">
            <div className="col-12">
              <h6
                dangerouslySetInnerHTML={{
                  __html: this.state.header && this.state.header.innerText
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: this.state.sample && this.state.sample
                }}
              />
            </div>
          </div>
        </div>

        <div className="readme-card-footer">
          <div className="row">
            <div className="col-12">
              <button
                onClick={this.viewReadme}
                className="ghost-button ghost-button-small"
              >
                <span>READ ME</span>
              </button>
              {this.state.numOfComments &&
              <a
                className="pull-right ml-2r"
                onClick={this.viewReadmeComments}
              >
                <img
                  src={CommentImage}
                  className="comment-number-button"
                />
                {this.state.numOfComments}
              </a>
              }
              {this.state.privateReadme &&
              <span className="pull-right ml-2"><img src={privateIcon} style={{width: '27px'}}/></span>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReadmeCard;
