import React from 'react';
import Info from '../../../../assets/images/MR_info.png';
import Public from '../../../../assets/images/MR_SwtcR.svg';
import Private from '../../../../assets/images/MR_SwtcL.svg';

export default class PublicPrivateSwitch extends React.Component {
  togglePrivacy = () => {
    this.props.togglePrivacy();
  }

  render() {
    return(
      <span>
        <span className='d-none d-lg-inline-block'>
        {
          this.props.private ?
            <span className='public-private-wrapper'>
              <span className='active'>
                PRIVATE
              </span>

              <img className='action-button-icon public-private-toggle' src={Private} />

              <span className='inactive'>
                PUBLIC
              </span>
            </span>
          :
            <span className='public-private-wrapper'>
              <span className='inactive'>
                PRIVATE
              </span>

              <img className='action-button-icon public-private-toggle' src={Public} />

              <span className='active'>
                PUBLIC
              </span>
            </span>
        }

          <span className='info-circle-wrapper'>
            <img src={Info} className='ml-2 info-circle'/>

            <span className='popup info-popup'>
              In PRIVATE MODE, no one can see this readme unless you share the
              link with them.
            </span>
          </span>

          {this.props.privacyPopup && this.props.private &&
            <span className='popup public-private'>
              Making your Readme public means that others will be able to
              discover it on the Community page - this can help your personal
              and company brand.<br/>
              <a href='javascript:void(0)' className='float-left mt-4' onClick={this.props.togglePrivacy}>Yes, go public!</a>
              <a href='javascript:void(0)' className='float-right mt-4' onClick={this.props.togglePrivacyPopup}>No</a>
            </span>
          }
        </span>

        <span className='d-inline-block d-lg-none'>
        {
          this.props.private ?
            <span className='public-private-wrapper text-center'>
              <img className='action-button-icon public-private-toggle' src={Private} />

              <div className='active'>
                PRIVATE
              </div>
            </span>
          :
            <span className='public-private-wrapper text-center'>
              <img className='action-button-icon public-private-toggle' src={Public} />

              <div className='active'>
                PUBLIC
              </div>
            </span>
        }

          {this.props.privacyPopup && this.props.private &&
            <span className='popup public-private'>
              Making your Readme public means that others will be able to see it, that okay?<br/>
              <a
                href='#'
                className='float-left mt-4'
                onClick={this.props.togglePrivacy}
              >
                Yes, go public!
              </a>
              <a
                href='#'
                className='float-right mt-4'
                onClick={this.props.togglePrivacyPopup}
              >
                No
              </a>
            </span>
          }
        </span>
      </span>
    )
  }
}
