import React from "react";
import Lockr from "lockr";
import { Api } from "../middleware/api";
import moment from "moment";
import _ from "lodash";
import nextUntil from "../lib/nextUntil.js";
import CollectFeedback from "./Readme/CollectFeedback";
import queryString from "query-string";
import isMobile from "ismobilejs";

// Components
import PublicPrivateSwitch from "./PublicPrivateSwitch";
import FirstView from "./FirstView";
import Comments from "./Comments";
import AuthorSection from "./AuthorSection";
import CommentsNumber from "./Comments/CommentsNumber";
import LinkableName from "./Company/LinkableName";

// Readme Components
import CopyLink from "./Readme/CopyLink";

// Images
import Edit from "../../../../assets/images/MR_edit.svg";
import Lock from "../../../../assets/images/MR_lock.svg";
import Fingers from "../../../../assets/images/MR_fingers.svg";
import Feedback from "../../../../assets/images/MR_feedback.svg";
import Presentation from "../../../../assets/images/MR_presentation.png";
import Doc from "../../../../assets/images/MR_doc.png";
import profileImage from '../../../../assets/images/default_profile_Image.png';

class Readme extends React.Component {
  constructor(props) {
    super(props);
    const currentUser = Lockr.get("managerReadme:currentUser");

    this.state = {
      allPages: [],
      visible: false,
      currentUser: currentUser,
      isAdminOrReviewer: currentUser && currentUser.data && (currentUser.data.is_admin || currentUser.data.reviewer),
      convertedContent: "",
      presentation: false,
      pageNumber: 0,
      updatePraises: false,
      linkCopiedPopup: false,
      feedbackPopup: false,
      feedbackText: "Copy Text",
      comments: [],
    };
  }

  componentWillReceiveProps(newProps) {
    let code = newProps.match.params.code;
    let username = newProps.match.params.username.toLowerCase();
    this.mountComponent(code, username)
  }

  componentDidMount() {
    let code = this.props.match.params.code;
    let username = this.props.match.params.username.toLowerCase();
    this.mountComponent(code, username)
  }

  mountComponent = (code, username) => {
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Token token=${Lockr.get("managerReadme:jwt")}`
    };

    document.onkeydown = evt => {
      evt = evt || window.event;

      let isEscape = false;

      if ("key" in evt) {
        isEscape = evt.key == "Escape" || evt.key == "Esc";
      } else {
        isEscape = evt.keyCode == 27;
      }

      if (isEscape) {
        this.resetPopups();
      }
    };

    let url = code ? `/readmes/${username}/${code}` : `/readmes/${username}`;

    this.setState({
      url: `https://managerreadme.com${url}`
    });

    fetch(url, {
      headers: headers
    })
      .then(res => {
        if (!res.ok) {
          Api.logOut();
        } else {
          return res.json();
        }
      })
      .then(data => {
        if (data.errors) {
          window.location.href = "/community";
        } else {
          this.readByLine(data);
        }
      })
      .catch(() => {
        Api.logOut();
        window.location.href = "/";
      });

    var shiftWindow = function() {
      scrollBy(0, -50);
    };
    if (location.hash) shiftWindow();

    window.addEventListener("hashchange", shiftWindow);
  };

  adminEdit = () => {
    if (this.props.match.params.code) {
      var url = `/edit-readme/${this.props.match.params.username}/${
        this.props.match.params.code
      }`;
    } else {
      url = `/edit-readme/${this.props.match.params.username}`;
    }

    window.location.href = url;
  };

  changePage = pageNumber => {
    this.setState({
      pageNumber: pageNumber
    });
    window.history.pushState({}, null, `?type=presentation&page=${pageNumber}`);
  };

  copyEmailToClipboard = () => {
    let ta = document.getElementById("feedback");

    ta.select();
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    this.setState({
      feedbackText: "Text copied!"
    });
  };

  copyReadmeLink = () => {
    this.resetPopups();
		const user = this.state.data.user;
		const readme = this.state.data.readme;
    let textField = document.createElement("textarea");

    if (readme.private) {
      var link = `https://${window.location.host}/readme/${user.name}/${
        user.code
      }`;
    } else {
      link = `https://${window.location.host}/readme/${user.name}`;
    }

    this.setState({ url: link, linkCopiedPopup: true });

    textField.innerText = link;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };

  createPresentationPages = text => {
    let allPages = this.state.allPages;
    let parser = new DOMParser();
    let doc = parser.parseFromString(text, "text/html");
    let elements = [].slice.call(doc.getElementsByTagName("*")).reverse();
    let pages = [];

    for (var i = elements.length; i--; ) {
      let element = elements[i];

      if (element.nodeName === "H1") {
        element.id = element.innerText.replace(/\s/g, "").toLowerCase();
        let siblings = nextUntil(element, "h1");

        let page = {
          header: element,
          body: siblings
        };

        if (element.innerHTML !== "<br>") {
          pages.push(page);
        }
      }
    }

    this.setState({
      allPages: pages
    });
  };

  isFirstPage = () => {
    if (this.state.pageNumber !== 0) {
      return false;
    } else {
      return true;
    }
  };

  isLastPage = () => {
    let totalPages = this.state.allPages.length;

    if (totalPages - 1 > this.state.pageNumber) {
      return false;
    } else {
      return true;
    }
  };

  readByLine = data => {
    let parser = new DOMParser();
    let doc = parser.parseFromString(data.readme.content, "text/html");
    let navLinks = doc.getElementsByTagName("h1");
    let parsedString = queryString.parse(window.location.search);

    for (var i = navLinks.length; i--; ) {
      if (navLinks[i].innerHTML === "<br>") {
        navLinks[i].remove();
      }
    }

    this.setState({
      data: data,
      private: data.readme.private,
      visible: true,
      htmlContent: data.readme.content,
      navLinks: navLinks,
      presentation: parsedString.type === "presentation" ? true : false
    });

    let headers = document.getElementsByTagName("h1");

    _.forEach(headers, header => {
      header.id = header.innerText.replace(/\s/g, "").toLowerCase();
    });

    this.createPresentationPages(data.readme.content);

    if (parsedString.type === "doc") {
      this.jumpToLocation(parsedString.page);
    } else if (parsedString.type === "presentation") {
      this.changePage(parseInt(parsedString.page));
    }
  };

  goToEditReadme = () => {
    window.location.href = "/edit-readme";
  };

  jumpToLocation = location => {
    let headers = document.getElementsByTagName("h1");

    _.forEach(headers, header => {
      header.id = header.innerText.replace(/\s/g, "").toLowerCase();
    });

    let element = document.getElementById(location);
    this.scrollTo(
      document.documentElement,
      element.offsetTop + this.scrollSize(),
      300
    );
    window.history.pushState({}, null, `?type=doc&page=${location}`);
  };

  nextPage = () => {
    this.setState({
      pageNumber: this.state.pageNumber + 1
    });

    window.history.pushState(
      {},
      null,
      `?type=presentation&page=${this.state.pageNumber + 1}`
    );
  };

  scrollSize = () => {
    if (isMobile.any) {
      return 750;
    } else {
      return 70;
    }
  };

  openTwitterShare = () => {
    let user = this.state.data.user;
    let href = `https://managerreadme.com/readme/${user.name}`;

    let name = user.provider === 'twitter' ? `@${user.name}` : user.display_name;
    let message = `Check out ${name}'s User Guide as a manager;`;
    let url = `https://twitter.com/intent/tweet?url=${href}&text=${message}`;

    window.open(url, "_blank");
  };

  resetPopups = () => {
    this.setState({
      feedbackPopup: false,
      privacyPopup: false,
      linkCopiedPopup: false,
      feedbackText: "Copy Text"
    });
  };

  prevPage = () => {
    this.setState({
      pageNumber: this.state.pageNumber - 1
    });

    window.history.pushState(
      {},
      null,
      `?type=presentation&page=${this.state.pageNumber - 1}`
    );
  };

  toggleFeedback = () => {
    this.resetPopups();

    this.setState({
      feedbackPopup: !this.state.feedbackPopup
    });
  };

  toggleLinkCopied = event => {
    this.resetPopups();
    event.stopPropagation();

    this.setState(prevState => {
      linkCopiedPopup: !prevState.linkCopiedPopup
    });
  };

  togglePrivacy = () => {
    let payload = {
      readme: {
        id: this.state.data.readme.id,
        private: !this.state.private
      }
    };

    Api.saveReadme(payload, Lockr.get("managerReadme:jwt")).then(res => {
      const isPrivate = res.readme.private;

      let currentUser = {
        data: res.user,
        exp: this.state.currentUser.exp,
        readme: res.readme,
        readme_private: isPrivate
      };

      Lockr.set("managerReadme:currentUser", currentUser);

      const url = window.location.href;
      let newUrl;
      if (isPrivate) {
        newUrl = `${url}/${res.user.code}`;
      } else {
        newUrl = `/readme/${res.user.name}`;
      }
      window.location.href = newUrl;

      this.setState({
        saving: false,
        private: isPrivate
      });
    });
  };

  togglePrivacyPopup = () => {
    this.resetPopups();

    this.setState({
      privacyPopup: !this.state.privacyPopup
    });
  };

  togglePresentationView = () => {
    this.setState({
      presentation: !this.state.presentation,
      pageNumber: 0
    });

    if (!this.state.presentation) {
      window.history.pushState(
        {},
        null,
        `?type=presentation&page=${this.state.pageNumber}`
      );
    } else {
      window.history.pushState({}, null, `?type=doc`);
    }
  };

  updatePraises = value => {
    this.setState({
      updatePraises: value
    });
  };

  scrollTo = (element, to, duration) => {
    if (duration <= 0) return;
    var difference = to - element.scrollTop;
    var perTick = difference / duration * 10;
    setTimeout(
      function() {
        element.scrollTop = element.scrollTop + perTick;
        if (element.scrollTop === to) return;
        this.scrollTo(element, to - 1, duration - 10);
      }.bind(this),
      10
    );
  };

  testFTV = () => {
    let parsedString = queryString.parse(window.location.search);
    return parsedString.first_time_experience === "1";
  };

  render() {
    if (this.state.data && this.state.data.readme) {
      const { readme, user } = this.state.data;
      const page = this.state.allPages[this.state.pageNumber];
      let currentUserIsOwner = false;
      if (this.state.currentUser && user.id === this.state.currentUser.data.id) {
        currentUserIsOwner = true
      }
      let userImageStyle = {
        backgroundImage: `url(${user.profile_image || profileImage})`
      };

      const commentsUrl = `/readmes/${readme.id}/comments`;

      return (
        <div className="readme">
          <div className="user-info">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-4">
                  <div className="row">
                    <div className="col-12 col-lg-5 d-flex d-lg-block justify-content-center">
                      <div
                        className="user-image"
                        style={userImageStyle}
                      />
                    </div>

                    <div className="col-12 col-lg-7 pl-0">
                      <div className="d-flex align-items-center h100 br pl-0 pr-3">
                        <div className="user-info-fields text-center text-lg-left w100 mt-2">
                          <h5 className="mb-0 mt-0">{user.display_name}</h5>
                          <p className="mb-0">
                            {readme.position && `${readme.position}`}{" "}
                            <LinkableName company={readme.company} />
                          </p>

                          <p>
                            <small>
                              Updated:{" "}
                              {moment(readme.updated_at).format(
                                "MMMM DD, YYYY"
                              )}
                            </small>
                          </p>
                          {user.reviewer && (
                            <div className="reviewer-badge">
                              REVIEWER
                              <img className="fingers" src={Fingers} />

                              <div className="tt">
                                Manager Readme Reviewers are part of our
                                community, with a goal to help others improve
                                and clarify their writing. They will review your
                                Readme if you ask for it (Click on "Collect
                                Feedback" in your Readme page), and
                                provide you with suggestions and tips.
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 bt-md d-block d-lg-none">
                  <div className="row">
                    <div className="col-6 col-lg-2 d-flex align-items-center justify-content-center br-md">
                      <div className="text-center">
                        <h5>{readme.experience}</h5>
                        <small className="small">
                          years of managerial experience
                        </small>
                      </div>
                    </div>
                    {
                      <div
                        className={
                          this.state.private
                            ? "col-6 col-lg-3 d-flex align-items-center justify-content-center"
                            : "col-6 col-lg-3 d-flex align-items-center justify-content-center br"
                        }
                      >
                        <div className="text-center">
                          <h5>{readme.manager_type}</h5>
                          <small>
                            {readme.manager_type === "M"
                              ? "Manager"
                              : "Manager of Managers"}
                          </small>
                        </div>
                      </div>
                    }

                    <div className="col-md-3 d-none d-lg-flex align-items-center justify-content-end">
                      {!this.state.private && (
                        <button
                          className="twitter-share-button d-flex justify-content-center"
                          onClick={this.openTwitterShare}
                        >
                          <i className="fa fa-twitter fa-2x mr-1 float-left" />
                          <span className="mt-1">Share this Readme</span>
                        </button>
                      )}

                      <CopyLink
                        copyReadmeLink={this.copyReadmeLink}
                        linkCopiedPopup={this.state.linkCopiedPopup}
                        resetPopups={this.resetPopups}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-6 col-lg-2 d-none d-lg-flex align-items-center justify-content-center br-md">
                  <div className="text-center">
                    <h5 className="">{readme.experience}</h5>
                    <small>Years of managerial experience</small>
                  </div>
                </div>

                {
                  <div
                    className={
                      this.state.private
                        ? "col-6 col-lg-3 d-none d-lg-flex align-items-center justify-content-center"
                        : "col-6 col-lg-3 d-none d-lg-flex align-items-center br justify-content-center br"
                    }
                  >
                    <div className="text-center">
                      <h5>{readme.manager_type}</h5>
                      <small>
                        {readme.manager_type === "M"
                          ? "Manager"
                          : "Manager of Managers"}
                      </small>
                    </div>
                  </div>
                }

                <div className="col-md-3 d-none d-lg-flex align-items-center share-readme">
                  {!this.state.private && (
                    <button
                      className="twitter-share-button d-flex justify-content-center"
                      onClick={this.openTwitterShare}
                    >
                      <i className="fa fa-twitter fa-2x mr-1 float-left" />
                      <span className="mt-1">Share this Readme</span>
                    </button>
                  )}

                  <CopyLink
                    copyReadmeLink={this.copyReadmeLink}
                    linkCopiedPopup={this.state.linkCopiedPopup}
                    resetPopups={this.resetPopups}
                    copyLinkText={!this.state.private ? 'Copy link' : 'Copy private link'}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="readme-owner-bar d-none d-lg-block">
            <div className="container">
              {this.state.currentUser &&
              this.state.currentUser.data.id === readme.user_id ? (
                <div className="row">
                  <div className="col-6 col-lg-10">
                    <ul className="action-buttons d-flex align-items-center h100">
                      <li
                        className="br-light popup-container pub-switch"
                        onClick={
                          this.state.private
                            ? this.togglePrivacyPopup
                            : this.togglePrivacy
                        }
                      >
                        <PublicPrivateSwitch
                          readme={this.state.data.readme}
                          private={this.state.private}
                          privacyPopup={this.state.privacyPopup}
                          togglePrivacy={this.togglePrivacy}
                          togglePrivacyPopup={this.togglePrivacyPopup}
                        />
                      </li>

                      <li
                        className="br-light d-none d-lg-inline-block"
                        onClick={this.goToEditReadme}
                      >
                        <img className="action-button-icon" src={Edit} /> EDIT
                        README
                      </li>

                      <li className="br-light d-none d-lg-inline-block">
                        <span onClick={this.toggleFeedback}>
                          <img className="action-button-icon" src={Feedback} />{" "}
                          COLLECT FEEDBACK
                        </span>

                        {this.state.feedbackPopup && (
                          <div className="popup-container">
                            <div className="popup feedback">
                              <CollectFeedback
                                {...this.props}
                                feedbackText={this.state.feedbackText}
                                copyEmailToClipboard={this.copyEmailToClipboard}
                                toggleFeedback={this.toggleFeedback}
                                currentUser={this.state.currentUser}
                                resetPopups={this.resetPopups}
                              />
                            </div>
                          </div>
                        )}
                      </li>
                      <li className="br-light d-none d-lg-inline-block">
                        <CommentsNumber
                          currentUserIsOwner={currentUserIsOwner}
                          commentsUrl={commentsUrl}
                          showAllComments={currentUserIsOwner || this.state.isAdminOrReviewer}
                        />
                      </li>
                    </ul>
                  </div>

                  <div className="d-none d-lg-flex col-lg-2 align-items-center justify-content-end">
                    <span className="d-none d-lg-block view-mode">
                      View as
                    </span>
                    {this.state.presentation ? (
                      <img
                        onClick={this.togglePresentationView}
                        className="toggle-view-button"
                        src={Presentation}
                      />
                    ) : (
                      <img
                        onClick={this.togglePresentationView}
                        className="toggle-view-button"
                        src={Doc}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      {this.state.currentUser &&
                        this.state.currentUser.data.is_admin &&
                        this.state.currentUser.data.id !== readme.user_id && (
                          <div className="d-flex justify-content-begin logged-out-padding align-items-center float-left">
                            <button
                              className="ghost-button ghost-button-small mr-2"
                              onClick={this.adminEdit}
                            >
                              Admin Edit
                            </button>
                          </div>
                        )}
                    </div>

                    <div className="d-flex logged-out-padding align-items-center justify-content-between mt-2">
                      <CommentsNumber
                        currentUserIsOwner={currentUserIsOwner}
                        commentsUrl={commentsUrl}
                        showAllComments={currentUserIsOwner || this.state.isAdminOrReviewer}
                      />

                      {readme.private && (
                        <div className="d-flex justify-content-begin logged-out-padding align-items-center float-left">
                          <span className="privacy-warning d-flex align-items-center">
                            <img src={Lock} className="float-left" />
                            <div>
                              <strong>
                                This Readme is private!
                              </strong>
                            </div>
                          </span>
                        </div>
                      )}

                      <div className="justify-content-between">
                        <span className="view-mode">
                          View as
                        </span>
                        {this.state.presentation ? (
                          <img
                            onClick={this.togglePresentationView}
                            className="toggle-view-button"
                            src={Presentation}
                          />
                        ) : (
                          <img
                            onClick={this.togglePresentationView}
                            className="toggle-view-button"
                            src={Doc}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="container readme-content">
            <div className="row">
              <div>
                {((this.state.currentUser &&
                  this.state.currentUser.data.first_view &&
                  (this.state.currentUser.data.id === readme.user_id)) ||
                  this.testFTV()) && (
                  <FirstView
                    {...this.props}
                    currentUser={this.state.currentUser}
                  />
                )}
              </div>

              <div className="col-lg-4 readme-left">
                <div className="readme-left-inner-wrapper">
                  <div className="row">
                    <div className="col-12">
                      <ul>
                        {this.state.presentation &&
                          _.map(this.state.navLinks, (link, index) => {
                            return (
                              <li key={index}>
                                <a
                                  onClick={() => this.changePage(index)}
                                  href="#"
                                >
                                  {link.innerText}
                                </a>
                              </li>
                            );
                          })}
                        {!this.state.presentation &&
                          _.map(this.state.navLinks, (link, index) => {
                            return (
                              <li key={index}>
                                <button
                                  onClick={() =>
                                    this.jumpToLocation(
                                      link.innerText
                                        .replace(/\s/g, "")
                                        .toLowerCase()
                                    )
                                  }
                                  className="fake-link"
                                >
                                  {link.innerText}
                                </button>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-lg-none col-12 action-buttons pt-2 pb-2">
                <div className="d-flex align-items-center justify-content-between">
                  <CommentsNumber
                    currentUserIsOwner={currentUserIsOwner}
                    commentsUrl={commentsUrl}
                    showAllComments={currentUserIsOwner || this.state.isAdminOrReviewer}
                  />

                  <div>
                    <span className="view-mode">View as</span>
                    {this.state.presentation ? (
                      <img
                        onClick={this.togglePresentationView}
                        className="toggle-view-button"
                        src={Presentation}
                      />
                    ) : (
                      <img
                        onClick={this.togglePresentationView}
                        className="toggle-view-button"
                        src={Doc}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-8 readme-right">
                {!this.state.presentation && (
                  <div className="redactor-styles"
                    dangerouslySetInnerHTML={{ __html: this.state.htmlContent }}
                  />
                )}

                {page &&
                  this.state.presentation && (
                    <div>
                      <h1>{page.header.innerText}</h1>

                      {_.map(page.body, (body, index) => {
                        return (
                          <div key={index}>
                            {React.createElement(
                              body.nodeName.toLowerCase(),
                              {},
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: body.innerHTML
                                }}
                              />
                            )}
                          </div>
                        );
                      })}

                      {!this.isFirstPage() && (
                        <button
                          className="ghost-button mr-2 mt-5"
                          onClick={this.prevPage}
                        >
                          Prev
                        </button>
                      )}

                      {!this.isLastPage() && (
                        <button
                          className="ghost-button mt-5"
                          onClick={this.nextPage}
                        >
                          Next
                        </button>
                      )}
                    </div>
                  )}

                  <AuthorSection
                    readme={readme}
                    user={user}
                  />

                <Comments
                  readmeId={readme.id}
                  ownerName={user.display_name || user.name}
                  ownerId={user.id}
                  commentsUrl={commentsUrl}
                  showAllComments={currentUserIsOwner || this.state.isAdminOrReviewer}
                />
              </div>


              <div className="col-12 d-flex d-lg-none align-items-center justify-content-center mb-5">
                {!this.state.private && (
                  <button
                    className="twitter-share-button d-flex justify-content-center"
                    onClick={this.openTwitterShare}
                  >
                    <i className="fa fa-twitter fa-2x mr-1 float-left" />
                    <span className="mt-1">Share this Readme</span>
                  </button>
                )}

                <CopyLink
                  containerClass="ml-4"
                  copyReadmeLink={this.copyReadmeLink}
                  linkCopiedPopup={this.state.linkCopiedPopup}
                  resetPopups={this.resetPopups}
                />
              </div>

            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <i className="fa fa-3x fa-spinner fa-spin" />
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Readme;
