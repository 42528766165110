import React, { Component } from "react";

import UrlImage from "../../../../../assets/images/MR_link.svg";
import TwitterLogo from "../../../../../assets/images/CO_twitter.svg";
import FacebookLogo from "../../../../../assets/images/CO_facebook.svg";
import AngellistLogo from "../../../../../assets/images/CO_angellist.svg";
import LinkedinLogo from "../../../../../assets/images/CO_linkedin.svg";

const Link = ({url, logo, text}) =>
  <div className="social-button">
    <a target="_blank" href={url}>
      <div className="d-flex align-items-center">
        <img src={logo} />
        {text}
      </div>
    </a>
  </div>

class Links extends Component {
  render() {
    const { url, twitterUrl, facebookUrl, angellistUrl, linkedinUrl } = this.props;

    return (
      <div className="links">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center">
            {url &&
              <Link
                url={url}
                logo={UrlImage}
                text={this.formatCompanyUrl(url)}
              />
            }
            {twitterUrl &&
              <Link
                url={twitterUrl}
                logo={TwitterLogo}
                text={"TWITTER"}
              />
            }
            {facebookUrl &&
              <Link
                url={facebookUrl}
                logo={FacebookLogo}
                text={"FACEBOOK"}
              />
            }
            {angellistUrl &&
              <Link
                url={angellistUrl}
                logo={AngellistLogo}
                text={"ANEGLLIST"}
              />
            }
            {linkedinUrl &&
              <Link
                url={linkedinUrl}
                logo={LinkedinLogo}
                text={"LINKEDIN"}
              />
            }
          </div>
        </div>
      </div>
    )
  }

  formatCompanyUrl = url => {
    const lastIndexOfSlash = url.lastIndexOf("/");
    const firstIndexSlash = url.indexOf("/") + 1;

    const lastIndex = lastIndexOfSlash === firstIndexSlash ?
      url.length :
      lastIndexOfSlash;

    return url
      .substring(
        firstIndexSlash + 1,
        lastIndex
      )
      .toUpperCase();
  }
}

export default Links;
