import React from "react";
import "../lib/redactor.js";
import "../lib/table.js";
import "../lib/imagemanager.js";
import alignment from "../lib/alignment";
import Lockr from "lockr";
import moment from "moment";
import { Api } from "../middleware/api";
import Clipboard from "clipboard";
import Select from "react-select";
import { Prompt } from 'react-router'
import queryString from "query-string";
import { Image, Transformation } from 'cloudinary-react';
import { ToastContainer, toast } from 'react-toastify';
import { validateEmptyField } from "../utils/input.js";
import _ from 'lodash';

// Components
import CustomInput from "./CustomInput";
import SignInModal from "./SignInModal";
import Welcome from "./Welcome";
import PublicPrivateSwitch from "./PublicPrivateSwitch";
import AutocompleteInput from "./EditReadme/AutocompleteInput";

// Images
import Lock from "../../../../assets/images/MR_lock.svg";
import lightbulb from "../../../../assets/images/MR_inspiration.png";
import profileImage from '../../../../assets/images/default_profile_Image.png';

class EditReadme extends React.Component {

  toastId = null;

  constructor(props) {
    super(props);

    let currentUser = Lockr.get("managerReadme:currentUser");

    this.state = {
      readmeText: "",
      currentUser: currentUser,
      saving: false,
      dirty: false,
      isValid: true,
      isNewUser: currentUser === undefined,
      showWelcomeModal: false,
      privacyPopup: false,
      showInspiration: false,
      currentTopic: 0,
      currentAnswer: 0,
      showSignInModal: false,
      saveClicked: false,
      rendering: true,
      valids: {
        display_name: true,
        position: true,
        company_name: true,
        manager_type: true,
        experience: true
      }
    };

    this.interval = null;
    this.toggleSignInModal = this.toggleSignInModal.bind(this);
    this.saveReadme = this.saveReadme.bind(this);
  }

  componentDidMount() {
    let clipboard = new Clipboard(".feedback-copy");

    let headers = {
      "Content-Type": "application/json",
      Authorization: `Token token=${Lockr.get("managerReadme:jwt")}`
    };

    let url = '/readmes/default';
    if (!this.state.isNewUser) {
      let user = this.state.currentUser.data;
      url = `/readmes/${user.name}`;
      if (this.state.currentUser.readme_private) {
        url = `/readmes/${user.name}/${user.code}`;
      }
    }

    document.onkeydown = (evt) => {
      evt = evt || window.event;
      let isEscape = false;

      if ("key" in evt) {
        isEscape = (evt.key == "Escape" || evt.key == "Esc");
      } else {
        isEscape = (evt.keyCode == 27);
      }

      if (isEscape) {
        this.setState({
          showInspiration: false,
        });
      }
    };

    fetch(url, {
      headers: headers
    })
      .then(res => {
        if (!res.ok) {
          Api.logOut();
        } else {
          return res.json();
        }
      })
      .then(data => {
        if (data.errors) {
          window.location.href = "/community";
        } else {
          let { readme, user } = data;
          let dirty = false;

          // new user in the system that logged in after writing data
          if (this.hasLocalTmpData()) {
            user = this.mergeUserDataWithLocalTmpData(user);
            readme = this.mergeReadmeDataWithLocalTmpData(readme);
            this.deleteTmpLocalData();
            dirty = true;
          }

          if (this.shouldShowWelcomeModal(user.first_login)) {
            this.setState({ showWelcomeModal: true });
          }

          this.setState({
            readme: readme,
            readmeText: readme.content,
            rendering: false,
            currentUser: { data: user },
            dirty: dirty
          });

          this.setupRedactor(readme.content);
          this.setValidations(readme, readme.content, user.display_name);
        }
      })
      .then(() => {
        fetch("/topics", {
          headers: headers
        })
          .then(res => {
            return res.json();
          })
          .then(data => {
            this.setState({ topics: data });
          });
      });

    if (this.state.isNewUser) {
      return;
    }

    this.interval = setInterval(() => {
      if (!this.state.dirty) {
        return;
      }
      this.saveToServer(true);
    }, 30000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  uploadWidget = () => {
    const widget = cloudinary.createUploadWidget(
      {
        cloudName: "managerreadme",
        uploadPreset: "swzvum9l",
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          let currentUser = { ...this.state.currentUser };
          currentUser.data.profile_image = result.info.secure_url;
          this.setState({
            imagePublicId: result.info.public_id,
            currentUser: currentUser,
            dirty: true,
          });
        }
      }
    );
    widget.open();
  };

  shouldShowWelcomeModal = (firstLogin) => {
    return firstLogin && Lockr.get("managerReadme:acknowledgedWelcomeModal") === undefined;
  };

  saveToServer = autoSaving => {
    this.setState({ saveClicked: true });
    if (!this.shouldSave()) {
      return;
    }

    let { readme, currentUser } = this.state;
    let content = $R("#content", "source.getCode");
    let validField = validateEmptyField(content);

    if (!validField) {
      return;
    }
    readme.content = content;
    readme.updated_at = moment();

    let exp = currentUser.exp;
    let user = currentUser.data;
    if (this.state.isNewUser) {
      this.saveTmpDataToLocalStorage(user, readme);
      this.setState({ showSignInModal: true });
      return;
    }

    this.setState({ saving: true, isValid: true });
    let payload = { readme: readme };
    this.saveUser(user.name, user.display_name, user.profile_image).then(() => {
      this.saveReadme(payload, autoSaving, exp);
    });
  };

  saveTmpDataToLocalStorage = (user, readme) => {
    let userData = {
      user: { display_name: user.display_name },
      readme: {
        content: readme.content,
        company_name: readme.company_name,
        manager_type: readme.manager_type,
        experience: readme.experience,
        position: readme.position
      }
    };

    Lockr.set("managerReadme:tmpUserData", userData);
  };

  mergeUserDataWithLocalTmpData = (user) => {
    let newUser = _.clone(user, true);
    let tmpLocalData = Lockr.get("managerReadme:tmpUserData");
    newUser.display_name = tmpLocalData.user.display_name;
    return newUser;
  };

  mergeReadmeDataWithLocalTmpData = (readme) => {
    let newReadme = _.clone(readme, true);
    let tmpLocalData = Lockr.get("managerReadme:tmpUserData");
    _.merge(newReadme, tmpLocalData.readme);
    return newReadme;
  };

  deleteTmpLocalData = () => {
    Lockr.rm("managerReadme:tmpUserData");
  };

  hasLocalTmpData = () => {
    return Lockr.get("managerReadme:tmpUserData") !== undefined;
  };

  saveReadme = (payload, autoSaving, exp) => {
    Api.saveReadme(payload, Lockr.get("managerReadme:jwt")).then(res => {
      const { user, readme, ok } = res;
      if (ok) {
        this.saveCurrentUserToLocalStorage(user, readme, readme.private, exp);
        this.setState({ saving: false, dirty: false });

        if (!autoSaving) {
          let url = `/readme/${user.name}`;
          if (readme.private) {
            url = `/readme/${user.name}/${user.code}`;
          }
          window.location.href = url;
        }
      } else {
        this.emitErrorToastr();
      }
    });
  };

  saveUser = (name, displayName, profileImage) => {
    let userPayload = { name: name, display_name: displayName, first_login: false, profile_image: profileImage };
    let self = this;
    return Api.updateUser(userPayload, Lockr.get("managerReadme:jwt")).then(user => {
      let currentUser = {...this.state.currentUser};
      currentUser.data = user;
      Lockr.set("managerReadme:currentUser", currentUser);
    }).catch(function(error) {
      self.emitErrorToastr();
    });
  };

  emitErrorToastr = () => {
    const ErrorToastElement = () => <div className='error-toastr'><b>Failed to save your README :(</b><br/>Please copy the content of your Readme to avoid potential data loss, and refresh the page</div>;
    if (!toast.isActive(this.toastId)) {
      this.toastId = toast.error(ErrorToastElement, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  shouldSave = () => {
    return this.validateInputs(this.state.valids);
  };

  setValidations = (readme, readmeText, displayName) => {
    let valids = {
      position: validateEmptyField(readme.position),
      company_name: validateEmptyField(readme.company_name),
      manager_type: validateEmptyField(readme.manager_type),
      experience: validateEmptyField(readme.experience),
      content: validateEmptyField(readmeText),
      display_name: validateEmptyField(displayName)
    };

    this.setState({ valids: valids, isValid: this.validateInputs(valids) });
  };

  validateInputs = (valids) => {
    for (let valid of Object.values(valids)) {
      if (!valid) {
        return false;
      }
    }
    return true;
  };

  saveCurrentUserToLocalStorage = (user, readme, readmePrivate, exp) => {
    let currentUser = { data: user, exp: exp, readme: readme, readme_private: readmePrivate };
    Lockr.set("managerReadme:currentUser", currentUser);
  };

  nextTopic = () => {
    if (this.state.currentTopic < this.state.topics.length - 1) {
      this.setState({ currentTopic: this.state.currentTopic + 1, currentAnswer: 0 });
    }
  };

  prevTopic = () => {
    if (this.state.currentTopic > 0) {
      this.setState({ currentTopic: this.state.currentTopic - 1, currentAnswer: 0 });
    }
  };

  nextAnswer = () => {
    if (this.state.currentAnswer < this.state.topics[this.state.currentTopic].answers.length - 1) {
      this.setState({ currentAnswer: this.state.currentAnswer + 1 });
    }
  };

  prevAnswer = () => {
    if (this.state.currentAnswer > 0) {
      this.setState({ currentAnswer: this.state.currentAnswer - 1 });
    }
  };

  toggleInspiration = () => {
    this.setState({
      showInspiration: !this.state.showInspiration,
      currentTopic: 0,
      currentAnswer: 0,
    });
  };

  togglePrivacy = () => {
    this.setState({
      readme: Object.assign({}, this.state.readme, { private: !this.state.readme.private }),
      dirty: true
    });
  };

  togglePrivacyPopup = () => {
    this.setState({ privacyPopup: !this.state.privacyPopup });
  };

  toggleSignInModal = () => {
    this.setState(prevState => this.setState({ showSignInModal: !prevState.showSignInModal }))
  };

  updateUserDisplayName = event => {
    let currentUser = {...this.state.currentUser};
    let displayName = event.target.value;
    currentUser.data.display_name = displayName;

    let validField = validateEmptyField(displayName);
    let newState = {
      currentUser: currentUser,
      dirty: true,
      valids: Object.assign({}, this.state.valids, { display_name: validField })
    };

    if (!validField) {
      newState.isValid = false;
    } else {
      let valids = _.clone(this.state.valids, true);
      valids.display_name = true;
      newState.isValid = this.validateInputs(valids);
    }
    this.setState(newState);
  };

  updatePosition = event => {
    this._updateReadmeState('position', event.target.value);
  };

  updateCompanyName = (event, value = null) => {
    const name = event ? event.target.value : value;
    this._updateReadmeState('company_name', name);
  };

  updateExperience = response => {
    this._updateReadmeState('experience', response.value);
  };

  updateManagerType = response => {
    this._updateReadmeState('manager_type', response.value);
  };

  _updateReadmeState = (key, value) => {
    let readmeObj = {}, validsObj = {};
    readmeObj[key] = value;
    validsObj[key] = validateEmptyField(value);

    let newState = {
      readme: Object.assign({}, this.state.readme, readmeObj),
      dirty: true,
      valids: Object.assign({}, this.state.valids, validsObj)
    };

    if (!validsObj[key]) {
      newState.isValid = false;
    } else {
      let valids = _.clone(this.state.valids, true);
      valids[key] = true;
      newState.isValid = this.validateInputs(valids);
    }

    this.setState(newState);
  };

  testFTE = () => {
    let parsedString = queryString.parse(window.location.search);
    return parsedString.first_time_experience === "1";
  };

  setupRedactor = (readmeContent) => {

    let content = document.getElementById("content");

    $R(content, {
      buttons: ["html", "format", "bold", "italic", "ul", "link", "redo", "undo", "deleted", "image"],
      imageUpload: '/images',
      formatting: ["p", "blockquote", "h1", "h2", "pre"],
      plugins: ["alignment", "table", "imagemanager"],
      toolbarFixedTopOffset: 80,
      callbacks: {
        click: e => {
          this.setState({ dirty: true });
        }
      }
    });

    $R("#content", "source.setCode", readmeContent);
  };

  render() {
    const { readme, isNewUser, currentUser, showSignInModal } = this.state;
    const user = (currentUser && currentUser.data) ? currentUser.data : undefined;

    if (readme && user) {
      let userImage = user.profile_image ? user.profile_image : profileImage;
      const userImageStyle = { backgroundImage: "url(" + userImage + ")" };
      return (
        <div className="edit-readme">
          <Prompt
            when={this.state.dirty}
            message="Changes you made may not be saved. Do you want to leave?"
          />
          <div className="user-info">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-2 user-image-container">
                  {!this.state.imagePublicId &&
                  <div className="user-image" style={userImageStyle} onClick={this.uploadWidget} />
                  }
                  {this.state.imagePublicId &&
                  <Image cloudName="managerreadme"
                         className="user-image"
                         publicId={this.state.imagePublicId}
                         radius="max"
                         onClick={this.uploadWidget}>
                    <Transformation width="120" height="120" gravity="faces" crop="fill" />
                  </Image>
                  }
                </div>

                <div className="col-12 col-lg-10">
                  <div className="row">
                    <div className="col-md-4 col-xs-6">
                      <div className="text-left">
                        <label className="mr-3">Display Name</label>
                        <br />
                        <CustomInput placeholder="Display Name"
                                     type="text"
                                     name="name"
                                     defaultValue={user.display_name}
                                     onChangeFunc={this.updateUserDisplayName}
                                     showError={!this.state.valids.display_name && this.state.saveClicked}
                                     errorText="Display name can't be blank"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-6">
                      <div className="text-left">
                        <label className="mr-3">Years of managerial experience</label>
                        <br />
                        <Select
                          name="experience"
                          placeholder="Please select...."
                          clearable={false}
                          value={
                            readme.experience
                              ? readme.experience
                              : "0-2"
                          }
                          onChange={this.updateExperience}
                          options={[
                            { value: "0-2", label: "0-2 Years" },
                            { value: "3-8", label: "3-8 Years" },
                            { value: "8+", label: "8+ Years" }
                          ]}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4">
                      <div className="text-left">
                        <label className="mr-3">Title</label>
                        <br />
                        <CustomInput placeholder="Position"
                                     type="text"
                                     name="position"
                                     defaultValue={readme.position}
                                     onChangeFunc={this.updatePosition}
                                     showError={!this.state.valids.position && this.state.saveClicked}
                                     errorText="Position can't be blank"
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-xs-6">
                      <div className="text-left">
                        <label className="mr-3">Company</label>
                        <br />
                        <p className="at-seperator">@</p>

                        <AutocompleteInput
                          companyName={readme.company_name}
                          updateCompanyName={this.updateCompanyName}
                          showError={!this.state.valids.company_name && this.state.saveClicked}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-xs-6">
                      <div className="text-left">
                        <label className="mr-3">Manager Level</label>
                        <Select
                          name="manager-type"
                          clearable={false}
                          placeholder="Please select...."
                          value={
                            readme.manager_type
                              ? readme.manager_type
                              : "M"
                          }
                          onChange={this.updateManagerType}
                          options={[
                            { value: "M", label: "Manager" },
                            { value: "M.O.M.", label: "Manager of Managers" }
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="readme-owner-bar">
            <div className="container">
              <div className="row">
                <div className="col-6 col-lg-7">
                  <ul className="action-buttons action-buttons d-flex align-items-center h100">
                    <li
                      className="br-light popup-container"
                      onClick={
                        readme.private
                          ? this.togglePrivacyPopup
                          : this.togglePrivacy
                      }
                    >
                      <PublicPrivateSwitch
                        readme={readme}
                        private={readme.private}
                        privacyPopup={this.state.privacyPopup}
                        togglePrivacy={this.togglePrivacy}
                        togglePrivacyPopup={this.togglePrivacyPopup}
                      />
                    </li>
                    <li>
                      {readme.private && (
                        <span className="privacy-warning d-none d-lg-inline">
                          <div className='privacy-warning-image mt-2 float-left'>
                            <img src={Lock}/>
                          </div>
                          <strong>
                            THIS README IS VISIBLE ONLY TO YOU
                          </strong>
                        </span>
                      )}
                    </li>
                  </ul>
                </div>

                <div className="col-6 col-lg-5 d-flex align-items-center justify-content-end">
                  <span
                    className="inspiration-button bl-light pl-2"
                    onClick={this.toggleInspiration}
                  >
                    <img src={lightbulb} className="align-middle mr-2" />{" "}
                    NEED INSPIRATION?
                  </span>

                  <button
                    className={"ghost-button ghost-button-small " + (this.state.isValid ? "" : "save-readme-btn")}
                    onClick={() => this.saveToServer(false)}
                  >
                    {this.state.saving ? (
                      <span>
                        <i className="fa fa-spinner fa-spin mr-2" />
                        <span> Saving</span>
                      </span>
                    ) : (
                      <span>Save all changes</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>

          {isNewUser &&
          <SignInModal
            toggleSignInModal={this.toggleSignInModal}
            show={showSignInModal}
            header={"Sign up to save your changes!"}
          />
          }

          <ToastContainer autoClose={8000} style={{position: 'fixed', minWidth: '400px'}}/>
          <div className="row light-grey-section">
            {(this.state.showWelcomeModal || this.testFTE()) && (
              <Welcome {...this.props} />
            )}
            <div className="container">
              <div className="col-12 col-md-10 ml-auto mr-auto">
                <div className="readme-editor">
                  <textarea id="content">{this.state.readmeText.content}</textarea>
                </div>
              </div>
            </div>

            {this.state.showInspiration && (
              <div className="inspiration">
                <div className="inspiration-header text-center">
                  <i
                    className="fa fa-close font-pink float-right"
                    onClick={this.toggleInspiration}
                  />
                  <h3>Need inspiration? We're here for you </h3>
                  <h5 className="mb-0">
                    Navigate between topics and thoughts of others to drive some
                    inspiration.
                  </h5>

                  <h5>
                    Copy relevant sentences or words, and build around them.
                  </h5>
                </div>

                <div className="inspiration-controls d-flex justify-content-between text-center">
                  <span
                    className="prev-topic font-pink br pr-3"
                    onClick={this.prevTopic}
                  >
                    Previous Topic
                  </span>
                  <span className="topic-title">
                    {this.state.topics[this.state.currentTopic].title}
                  </span>
                  <span
                    className="next-topic font-pink bl pl-3"
                    onClick={this.nextTopic}
                  >
                    Next Topic
                  </span>
                </div>

                <i onClick={this.prevAnswer} className='fa fa-2x fa-chevron-left prev-answer font-white'/>
                <i onClick={this.nextAnswer} className='fa fa-2x fa-chevron-right next-answer font-white'/>
                <div className="inspiration-answer">
                  <p dangerouslySetInnerHTML={{ __html:this.state.topics[this.state.currentTopic].answers[
                      this.state.currentAnswer
                      ].content}}>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <i className="fa fa-3x fa-spinner fa-spin" />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default EditReadme;
