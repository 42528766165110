import React from 'react';
import FOF from '../../../../assets/images/mr_404.png';
// Components

class FourOhFour extends React.Component {
  render() {
    return(
      <div className='error-page'>
        <div className='container text-center'>
          <div className='row'>
            <div className='col-12 mt-5'>
              <h4 className='mb-5'>Oops!<br/>Something went wrong...</h4>
              <img src={FOF} />
            </div>

            <div className='col-12 mt-5'>
              <a href='/' className='ghost-button'>Go back home</a>
            </div>
          </div>

          <div className='row'>

          </div>
        </div>
      </div>
    )
  }
}

export default FourOhFour
