import React from 'react';
import Select from 'react-select';
import Lockr from 'lockr';
import moment from 'moment';
import '../lib/redactor.js';
import alignment from '../lib/alignment';
import {Api} from '../middleware/api';
import Lock from '../../../../assets/images/MR_lock.svg';

// Components
import PublicPrivateSwitch from './PublicPrivateSwitch';

class AdminEdit extends React.Component {
  constructor(props) {
    super(props);

    let currentUser = Lockr.get('managerReadme:currentUser');

    this.state = {
      currentUser: currentUser,
      saving: false,
      privacyPopup: false,
    };
  }

  componentDidMount() {
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token token=${Lockr.get('managerReadme:jwt')}`
    };

    const { code, username } = this.props.match.params;

    let url = code ? `/readmes/${username}/${code}` : `/readmes/${username}`;

    fetch(url, {
      headers: headers
    })
    .then((res) => {
      if(!res.ok) {
        Api.logOut();
      } else {
        return res.json();
      }
    })
    .then((data) => {
      if(data.errors) {
        window.location.href = '/community'
      } else {
        this.setState({
          user: data.user,
          readme: data.readme,
          rendering: false
        });

        this.setupRedactor(data.readme.content);
      }
    });
  }

  setupRedactor = (readmeContent) => {
    $R("#content", {
      buttons: [
        "html",
        "format",
        "bold",
        "italic",
        "ul",
        "link",
        "redo",
        "undo",
        "deleted",
        "image"
      ],
      imageUpload: '/images',
      formatting: ["p", "blockquote", "h1", "h2", "pre"],
      plugins: ["alignment", "table", "imagemanager"],
      toolbarFixedTopOffset: 80,
      callbacks: {
        click: e => {
          this.setState({ dirty: true });
        }
      }
    });

    $R("#content", "source.setCode", readmeContent);
  };

  saveReadme = () => {
    const { readme } = this.state;
    this.setState({ saving: true });

    const content = $R("#content", "source.getCode");

    const payload = {
      readme: {
        id: readme.id,
        content: content,
        company_name: readme.company_name,
        experience: readme.experience,
        manager_type: readme.manager_type,
        position: readme.position,
        private: readme.private,
        updated_at: moment()
      },
      autosave: false
    };

    Api.saveReadme(payload, Lockr.get('managerReadme:jwt')).then((res) => {
      this.setState({ saving: false });

      const { code, username } = this.props.match.params;
      let url = readme.private ? `/readme/${username}/${code}` : `/readme/${username}`;
      window.location.href = url;
    });
  };

  togglePrivacy = () => {
    this.setState({
      readme: Object.assign({}, this.state.readme, {private: !this.state.readme.private})
    });
  };

  togglePrivacyPopup = () => {
    this.setState({
      privacyPopup: !this.state.privacyPopup
    });
  };

  updatePosition = (event) => {
    this.setState({
      readme: Object.assign({}, this.state.readme, {position: event.target.value})
    });
  };

  updateCompanyName = (event) => {
    this.setState({
      readme: Object.assign({}, this.state.readme, {company_name: event.target.value})
    });
  };

  updateExperience = (response) => {
    this.setState({
      readme: Object.assign({}, this.state.readme, {experience: response.value})
    });
  };

  updateManagerType = (response) => {
    this.setState({
      readme: Object.assign({}, this.state.readme, {manager_type: response.value})
    });
  };

  render() {
    let image = this.state.user && this.state.user.profile_image;

    let userImageStyle = {
      backgroundImage: 'url('+ image +')'
    };

    if (this.state.readme) {
      return(
        <div className='edit-readme'>
          <div className='user-info'>
            <div className='container'>
              <div className="row">
                <div className="col-12 col-lg-2 user-image-container">
                  <div className="user-image" style={userImageStyle}/>
                </div>

                <div className="col-12 col-lg-10">
                  <div className="row">
                    <div className="col-md-4 col-xs-6">
                      <div className="text-left">
                        <label className="mr-3">UserName</label>
                        <br />
                        <input
                          type="text"
                          defaultValue={this.props.match.params.username}
                          readOnly={true}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-6">
                      <div className="text-left">
                        <label className="mr-3">Years of managerial experience</label>
                        <br />
                        <Select
                          name="experience"
                          placeholder="Please select...."
                          clearable={false}
                          value={
                            this.state.readme.experience
                              ? this.state.readme.experience
                              : ""
                          }
                          onChange={this.updateExperience}
                          options={[
                            { value: "0-2", label: "0-2 Years" },
                            { value: "3-8", label: "3-8 Years" },
                            { value: "8+", label: "8+ Years" }
                          ]}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4">
                      <div className="text-left">
                        <label className="mr-3">Title</label>
                        <br />
                        <input
                          type="text"
                          placeholder="Postion"
                          defaultValue={this.state.readme.position}
                          onChange={this.updatePosition}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-xs-6">
                      <div className="text-left">
                        <label className="mr-3">Title</label>
                        <br />
                        <p className="at-seperator">@</p>
                        <input
                          type="text"
                          placeholder="Company"
                          defaultValue={this.state.readme.company_name}
                          onChange={this.updateCompanyName}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-xs-6">
                      <div className="text-left">
                        <label className="mr-3">Manager Level</label>
                        <Select
                          name="manager-type"
                          clearable={false}
                          placeholder="Please select...."
                          value={
                            this.state.readme.manager_type
                              ? this.state.readme.manager_type
                              : ""
                          }
                          onChange={this.updateManagerType}
                          options={[
                            { value: "M", label: "Manager" },
                            { value: "M.O.M.", label: "Manager of Managers" }
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='readme-owner-bar'>
            <div className='container'>
              <div className='row'>
                <div className='col-12 mt-4'>
                  <ul className='action-buttons'>
                    <li className='br-light popup-container' onClick={this.state.readme.private ? this.togglePrivacyPopup : this.togglePrivacy}>
                      <PublicPrivateSwitch
                        readme={this.state.readme}
                        private={this.state.readme.private}
                        privacyPopup={this.state.privacyPopup}
                        togglePrivacy={this.togglePrivacy}
                        togglePrivacyPopup={this.togglePrivacyPopup}
                      />
                    </li>
                    <li>
                      { this.state.readme.private &&
                          <span className='privacy-warning d-none d-lg-inline'>
                            <div className='privacy-warning-image'>
                              <img src={Lock}/>
                            </div>
                            <strong>THIS README IS VISIBLE ONLY TO YOU</strong>
                          </span>
                      }
                    </li>
                    <li className='float-right'>
                      <button
                        className='ghost-button ghost-button-small'
                        onClick={() => this.saveReadme()}>
                        {
                          this.state.saving ?
                            <span>
                              <i className='fa fa-spinner fa-spin mr-2'/>
                              <span> Saving</span>
                            </span>
                          :
                            <span>Save all changes</span>
                        }
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className='row light-grey-section'>
            <div className='col-11 col-sm-10 col-md-10 col-lg-8 col-xl-8 ml-auto mr-auto'>
              <div className='readme-editor'>
                <textarea id='content'>
                </textarea>
              </div>
            </div>
          </div>

        </div>
      )
    } else {
      return(
        <div>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <i className='fa fa-3x fa-spinner fa-spin' />
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default AdminEdit
