import React, { Component } from 'react';
import CompanyCard from "../Company/Card";

class CompanyShowcase extends Component {
  state = {
    hideMoreCompanies: false,
    page: 0
  }

  componentDidMount() {
    this.fetchCompanies();
  }

  render() {
    const companies = this.state.companies;

    if (!companies) return null;

    if (companies.length === 0) return null;

    return (
      <div className='readmes-index light-grey-section company-index'>
        <div className='container-fluid white-section'>
          <div className='row'>
            <div className='col-12'>
              <h2 className='text-center'>
                Companies with Manager Readmes
              </h2>
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='d-flex flex-wrap'>
            {companies.map(company =>
              <CompanyCard
                key={company.id}
                company={company}
              />
            )}
          </div>

          {!this.state.hideMoreCompanies &&
            <div className="show-more text-center">
              <button
                onClick={this.loadCompanies}
                className="ghost-button"
              >
                See more companies
              </button>
            </div>
          }
        </div>
      </div>
    )
  }

  fetchCompanies = () => {
    const url = `/companies`;

    fetch(url)
      .then(res => {
        if (res.ok) {
          return res.json();
        } else {
          window.location.href = "/not_found"
        }
      })
      .then(data => {
        if (data.error) {
          window.location.href = "/not_found";
        } else {
          this.setState({ companies: data })
        }
      })
  }

  loadCompanies = (event) => {
    event.preventDefault();

    const url = `/companies?page=${this.state.page + 1}`;

    fetch(url)
      .then(res => {
        if (res.ok) {
          return res.json();
        } else {
          window.location.href = "/not_found"
        }
      })
      .then(data => {
        if (data.error) {
          window.location.href = "/not_found";
        } else {
          const paginationData = data[0]
          const companies = data[1]

          this.setState(prevState => {
            return {
              hideMoreCompanies: paginationData.page === paginationData.pages,
              companies: prevState.companies.concat(companies)
            }
          })
        }
      })
  }
}

export default CompanyShowcase;
