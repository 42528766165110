import React, { Component } from "react";
import FounderBackground from '../../../../../assets/images/BGNS.png';
import LogoIcon from '../../../../../assets/images/logo-icon.png';
import Lockr from "lockr";

class FounderComment extends Component {
  render() {
    const currentUser = Lockr.get("managerReadme:currentUser");

    if (currentUser) return null;

    return (
      <div className="founder">
        <div className="founder-info">
          <img
            src="https://pbs.twimg.com/profile_images/960233493467820032/X43zxv6t.jpg"
            className="profile-image"
          />

          <div className="founder-details">
            <div className="founder-name">
              <b>Oren Ellenbogen</b>
            </div>
            <div className="founder-description">
              <img className="founder-logo" src={LogoIcon} /> Manager Readme Founder
            </div>
          </div>
        </div>

        <img src={FounderBackground} className="founder-background" />
        <div className="founder-comment-tip" />
        {!this.props.showModal &&
        <div className="founder-comment-inner" />
        }
        <div className="founder-comment box-arrow">
          <b>Hey there, we see you aren't signed in.</b>
          <br />
          <br />
          <p>
            Please consider creating an account and join our
            community. It literally takes a few seconds and we'd love to see you
            create your Readme or provide feedback on others.
          </p>

          <button
            onClick={this.props.toggleSignInModal}
            className="ghost-button ghost-button-small pull-right"
          >
            Register Now
          </button>
        </div>

      </div>
    )
  }
}

export default FounderComment;
