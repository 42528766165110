import React from "react";

// Components
import Pitch from "./LandingPage/Pitch";
import WhyReadme from "./LandingPage/WhyReadme";
import SharedReadme from "./LandingPage/SharedReadme";
import HeaderBackground from "../../../../assets/images/MR_HP_top.png";
import MobileHeaderBackground from "../../../../assets/images/MR_Mobile_HP_top.png";
import Managers from "./LandingPage/Managers";

import isMobile from 'ismobilejs';

class LandingPage extends React.Component {
  render() {
    const style = {
      marginTop: (window.location.pathname === '/' && !isMobile.phone) && '40px'
    }
    return (
      <div className="landing-page">
        <div className="background-image-wrapper">
          <img style={style} className="background-image" src={HeaderBackground} />
        </div>
        {/* <img src={HeaderBackground} style={style}/> */}
        <Pitch {...this.props} />
        <WhyReadme {...this.props} />
        <Managers {...this.props} />
        <SharedReadme {...this.props} />
      </div>
    );
  }
}

export default LandingPage;
