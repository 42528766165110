import React, { Component } from "react";
import { Link, withRouter } from 'react-router-dom'
import isMobile from "ismobilejs";
import Lockr from "lockr";

class Preheader extends Component {
  constructor(props) {
    super(props);
    this.state = { showPreheader: this.shouldShowPreHeader(this.props.location.pathname, isMobile.phone) };
  }

  componentWillReceiveProps({ location }) {
    this.setState({ showPreheader: this.shouldShowPreHeader(location.pathname, isMobile.phone) });
  }

  shouldShowPreHeader(pathname, isMobile) {
    if (isMobile) {
      return false;
    }
    return (pathname === "/" || (pathname.startsWith("/readme") && !this.isRegisteredUser()));
  }

  isRegisteredUser() {
    const currentUser = Lockr.get("managerReadme:currentUser");
    return currentUser && currentUser.data && !currentUser.data.first_view;
  }

  render() {
    return (
      this.state.showPreheader && (
      <div className="reviewer-bar">
        <div className="container">
          <div className="d-flex justify-content-between">
            <div className="d-none d-lg-inline-block">
              <p className="font-white mt-0 mb-0">
                Want to receive feedback on your managerial philosophy?
                Our
                <span className="font-pink ml-1 mr-1">
                  <Link to="/reviewers" target="_blank">Reviewers</Link>
                </span> can help &nbsp;👉
                <Link to="/edit-readme" className="gradient-button">Write your Readme</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      )
    )
  }
}

export default withRouter(Preheader);
