import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import Lockr from "lockr";
import moment from "moment";

import { Api } from "./middleware/api";

// Top Level Components
import LandingPage from "./components/LandingPage";
import Features from "./components/Features";
import UseCases from "./components/UseCases";
import Community from "./components/Community";
import EditReadme from "./components/EditReadme";
import AdminEdit from "./components/AdminEdit";
import Readme from "./components/Readme";
import MyReadme from "./components/MyReadme";
import Login from "./components/Login";
import Admin from "./components/Admin";
import Snapshot from "./components/Snapshot";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import CodeOfConduct from "./components/CodeOfConduct";
import Reviewers from "./components/Reviewers";
import FourOhFour from "./components/404";
import Settings from "./components/Settings";
import Company from "./components/Company";
import ReviewRequest from "./components/ReviewRequest";

//Layout
import Preheader from "./components/Preheader";
import Header from "./components/Header";
import LayoutFooter from "./components/LayoutFooter";

// CURRENT USER CONSTANT
const currentUser = Lockr.get("managerReadme:currentUser");

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated(currentUser) && !isExpired(currentUser) ? (
        <Component {...props} />
      ) : (
        redirectPage(currentUser)
      )
    }
  />
);

function redirectPage(currentUser) {
  if (currentUser) {
    Api.logOut();
    return null;
  } else {
    window.location.href = "/auth/twitter";
    return null;
  }
}

const AppRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render = {props =>
      validateAuth(currentUser) ? (
        <Component {...props} />
      ) : (
        location.reload()
      )
    }
  />
);

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAdmin(currentUser) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);


const AdminOrReviewerRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAdminOrReviewer(currentUser) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

function validateAuth(currentUser) {
  if (isAuthenticated(currentUser) && isExpired(currentUser)) {
    Api.logOut();
    return false;
  }
  return true;
}

function isAuthenticated(currentUser) {
  return (currentUser !== undefined);
}

function isAdmin(currentUser) {
  return (currentUser && currentUser.data.is_admin);
}

function isAdminOrReviewer(currentUser) {
  return (currentUser && (currentUser.data.is_admin || currentUser.data.reviewer));
}

function isExpired(currentUser) {
  if (!currentUser) {
    return true;
  }
  let currentTimestamp = moment().unix();
  return moment(currentUser.exp).isBefore(currentTimestamp);
}

const style = {
  overflowX: "hidden",
  height: "100%"
};

const App = props => (
  <Router>
    <div style={style}>
      <Preheader/>
      <Header/>

      <Switch>
        <Route exact path="/" component={LandingPage} />
        <AppRoute exact path="/features" component={Features} />
        <AppRoute exact path="/use-cases" component={UseCases} />
        <AppRoute exact path="/community" component={Community} />
        <AppRoute exact path="/code-of-conduct" component={CodeOfConduct} />
        <AppRoute exact path="/terms" component={TermsOfService} />
        <AppRoute exact path="/privacy" component={PrivacyPolicy} />
        <AppRoute exact path="/reviewers" component={Reviewers} />
        <AppRoute exact path="/edit-readme" component={EditReadme} />
        <AppRoute exact path="/my-readme" component={MyReadme} />
        <PrivateRoute exact path="/settings" component={Settings} />
        <AdminRoute exact path="/edit-readme/:username/:code?" component={AdminEdit} />
        <AdminRoute exact path="/admin" component={Admin} />
        <AdminOrReviewerRoute exact path="/review-requests" component={ReviewRequest} />
        <AppRoute exact path={"/readme/:username/:code?"} component={Readme} />
        <AppRoute exact path={"/company/:slug"} component={Company} />
        <AppRoute exact path="/login" component={Login} />
        <AppRoute exact path="/snap/:username" component={Snapshot} />
        <AppRoute component={FourOhFour} />
      </Switch>
      <LayoutFooter/>

    </div>
  </Router>
);

export default App;
