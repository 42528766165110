import React from 'react';
import PageFooter from './PageFooter';

class CodeOfConduct extends React.Component {
  render() {
    return(
      <div className='code-of-conduct'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-lg-10 text-center text-lg-left mt-5'>
              <h1>
                Code of Conduct
              </h1>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 text-center text-lg-left section-1'>
              <h5>
              Our Pledge
              </h5>
              <p>
              In the interest of fostering an open and welcoming environment, 
              we as moderators of ManagerREADME pledge to making participation in our project and 
              our community a harassment-free experience for everyone, regardless of age, body size, disability, ethnicity, 
              gender identity and expression, level of experience, nationality, personal appearance, race, religion, or 
              sexual identity and orientation.
              </p>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 text-center text-lg-left section-1'>
                <h5>
                Our Standards
                </h5>
                <p>
                  Examples of behavior that contributes to creating a positive environment include:
                  <ul>
                    <li>Using welcoming and inclusive language</li>
                    <li>Being respectful of differing viewpoints and experiences</li>
                    <li>Gracefully accepting constructive criticism</li>
                    <li>Focusing on what is best for the community</li>
                    <li>Showing empathy towards other community members</li>
                  </ul>
                  <br/>
                  Examples of unacceptable behavior by participants include:
                  <ul>
                    <li>The use of sexualized language or imagery and unwelcome sexual attention or advances</li>
                    <li>Trolling, insulting/derogatory comments, and personal or political attacks</li>
                    <li>Public or private harassment</li>
                    <li>Publishing others' private information, such as a physical or electronic address, without explicit permission</li>
                    <li>Other conduct which could reasonably be considered inappropriate in a professional setting</li>
                  </ul>
                </p>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 text-center text-lg-left section-1'>                        
              <h5>
              Our Responsibilities
              </h5>
              <p>
                Moderators are responsible for clarifying the standards of acceptable behavior and are expected to 
                take appropriate and fair corrective action in response to any instances of unacceptable behavior.
                <br/><br/>
                Moderators have the right and responsibility to remove comments or other contributions that are not 
                aligned to this Code of Conduct, or to ban temporarily or permanently any members for other behaviors 
                that they deem inappropriate, threatening, offensive, or harmful.
              </p>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 text-center text-lg-left section-1'>                        
              <h5>
              Member Responsibilities
              </h5>
              <p>
                All participants in the community are expected to abide by Our Standards (described above).
                <br/><br/>
                Sustaining members have an even higher responsibility in helping us uphold the community’s values. 
                Any violations of our Code of Conduct are grounds for removal from the membership program.
              </p>
            </div>
          </div>

           <div className='row'>
            <div className='col-12 text-center text-lg-left section-1'>                        
              <h5>
              Scope
              </h5>
              <p>
              This Code of Conduct applies both online and in public spaces when an individual is representing the community. 
              Examples of representing a community include using an official e-mail address, posting via an official social media account, 
              or acting as an appointed representative at an online or offline event. Representation of the community may be further 
              defined and clarified by moderators.
              </p>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 text-center text-lg-left section-1'>                        
              <h5>
              Enforcement
              </h5>
              <p>
              Instances of abusive, harassing, or otherwise unacceptable behavior may be reported by contacting 
              the team at oren@managerreadme.com. All complaints will be reviewed and investigated and will result 
              in a response that is deemed necessary and appropriate to the circumstances. 
              The moderation team is obligated to maintain confidentiality with regard to the reporter of an incident. 
              Further details of specific enforcement policies may be posted separately.
              <br/><br/>
              Moderators who do not follow or enforce the Code of Conduct in good faith may face temporary or permanent 
              repercussions as determined by other members of the community's leadership.
              </p>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 text-center text-lg-left section-1'>                        
              <h5>
              Attribution
              </h5>
              <p>
              This Code of Conduct is adapted from the <a href="http://contributor-covenant.org/">Contributor Covenant</a>, version 1.4, available at <a href="https://www.contributor-covenant.org/version/1/4/code-of-conduct.html">http://contributor-covenant.org/version/1/4</a>.
              </p>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default CodeOfConduct;
